import { useEffect, useState, useContext } from "react";
import jsPDF from "jspdf";
import "svg2pdf.js";

import { Modal, ModalBody, ModalStateReturn } from "./base/Modal";
// import our font
import "./graphik-regular-normal";
import "./graphik-medium-normal";
import styled from "styled-components";
import { missingCaseError } from "../helpers/missingCaseError";
import { Button } from "./base/Button";
import { colors } from "../constants/theme";
import { Loader } from "./base/Loader";
import { MyChartDataAccessContext } from "../context/MyChartDataAccessContext";
import { format } from "date-fns";
import LogoImg from "../assets/images/logo_full.png";
import { useAuth } from "../context/AuthContext";
import { uiConstants } from "../uiConstants";
import { analytics } from "../analytics";
import { EVENTS } from "../analytics/events";

export const downloadTimelineId = 'download-timeline';

const getQuerySelector = (selector: string) => `#${downloadTimelineId} ${selector}`;

const downloadPdf = async ({ chartProfileDisplayName, currentUserName }: { chartProfileDisplayName?: string; currentUserName?: string }) => {
  const topPadding = 22.5;
  const bottomPadding = 40;
  const horizontalPadding = 40;
  const timeline = document.querySelector(getQuerySelector("#main"));
  const ticker = document.querySelector(getQuerySelector("#ticker"));
  const categoryLabelWidth = 130;
  const timelineWidth = timeline?.scrollWidth || 0;
  const timelineHeight = timeline?.scrollHeight || 0;
  const tickerHeight = ticker?.scrollHeight || 0;
  const leftOfTimelineWidth = horizontalPadding + categoryLabelWidth;
  const pageWidth = leftOfTimelineWidth + timelineWidth + horizontalPadding;

  const metadataHeight = 100;

  const height =
    topPadding + metadataHeight +
    timelineHeight +
    tickerHeight +
    bottomPadding;
  const doc = new jsPDF({
    orientation: pageWidth >= height ? "landscape" : "portrait",
    unit: "px",
    format: [pageWidth, height],
    hotfixes: ["px_scaling"],
    putOnlyUsedFonts: true,
  });

  // Metadata
  // logo
  const logoHeight = 65;
  const logoWidth = 250;
  doc.addImage(
    LogoImg,
    "PNG",
    horizontalPadding,
    topPadding + metadataHeight / 2 - logoHeight / 2,
    logoWidth,
    logoHeight
  );

  // title
  // Offset because logo isn't perfectly vertically centered
  const logoOffset = 3.5;
  const titleFontSize = 20;
  doc.setFont("Graphik Medium", "normal");
  doc.setFontSize(titleFontSize);
  doc.setTextColor(colors.dark85);
  await doc.text(
    `${chartProfileDisplayName}: health timeline`,
    horizontalPadding * 2 + logoWidth,
    topPadding + metadataHeight / 2 + titleFontSize / 2 + logoOffset
  );

  const exportedDateFontSize = 14;
  doc.setFont("Graphik Regular", "normal");
  doc.setFontSize(exportedDateFontSize);
  doc.setTextColor(colors.dark85);
  await doc.text(
    `Exported ${format(new Date(), "M/d/Y")}. Logged in as ${currentUserName}`,
    pageWidth - horizontalPadding,
    topPadding + metadataHeight / 2 + exportedDateFontSize / 2 + logoOffset,
    {
      align: "right",
    }
  );


  // End metadata
  doc.setFont("Graphik Regular", "normal");

  const tickerBottomMargin = 10;
  if (ticker) {
    const tickerRect = ticker.getBoundingClientRect();

    await doc.svg(ticker, {
      x: leftOfTimelineWidth + tickerRect.x,
      y: topPadding + tickerRect.y - uiConstants.navbar.height + metadataHeight,
      width: tickerRect.width,
      height: tickerRect.height + tickerBottomMargin,
      loadExternalStyleSheets: true,
    });
  }

  const charts = document.querySelectorAll(getQuerySelector("section .chart"));

  for (let i = 0; i < charts.length; i++) {
    const chart = charts[i];
    const chartRect = chart.getBoundingClientRect();

    await doc.svg(chart, {
      x: leftOfTimelineWidth + chartRect.x,
      y:
        topPadding +
        tickerBottomMargin +
        chartRect.y -
        uiConstants.navbar.height +
        metadataHeight,
      width: chartRect.width,
      height: chartRect.height,
      loadExternalStyleSheets: true,
    });
  }

  const chartLabels = document.querySelectorAll(
    getQuerySelector("section .chart-labels")
  );

  for (let i = 0; i < chartLabels.length; i++) {
    const chartLabel = chartLabels[i];
    const chartLabelRect = chartLabel.getBoundingClientRect();
    await doc.svg(chartLabel, {
      x: leftOfTimelineWidth + chartLabelRect.x,
      y:
        topPadding +
        tickerBottomMargin +
        chartLabelRect.y -
        uiConstants.navbar.height +
        metadataHeight,
      width: chartLabelRect.width,
      height: chartLabelRect.height,
      loadExternalStyleSheets: true,
    });
  }

  const categoryLabels: NodeListOf<HTMLSpanElement> = document.querySelectorAll(
    getQuerySelector("section .category-label")
  );
  const categoryLabelFontSize = 16;
  doc.setTextColor(colors.dark85);
  doc.setFontSize(categoryLabelFontSize);
  for (let i = 0; i < categoryLabels.length; i++) {
    const categoryLabel = categoryLabels[i];
    const categoryLabelRect = categoryLabel.getBoundingClientRect();

    const categoryLabelY =
      topPadding +
      tickerBottomMargin +
      categoryLabelRect.y -
      uiConstants.navbar.height +
      metadataHeight;

    if (i !== 0) {
      doc.setDrawColor(colors.light);
      doc.setLineWidth(0.5);
      const lineY = categoryLabelY - categoryLabelFontSize - 15;
      doc.line(
        horizontalPadding,
        lineY,
        pageWidth - horizontalPadding * 2,
        lineY
      );
    }

    await doc.text(
      categoryLabel.textContent || categoryLabel.innerText,
      categoryLabelRect.x + horizontalPadding,
      categoryLabelY,
      {
        maxWidth: categoryLabelWidth,
      }
    );
  }

  const docTitle = `${chartProfileDisplayName || 'your_timeline'}_Pictal_timeline_${format(new Date(), 'M-d-Y')}.pdf`;
  // save the created pdf
  doc.save(docTitle);
};

const Headline = styled('p')({
  fontFamily: "Graphik Medium",
  fontSize: 24,
  fontWeight: 500,
  lineHeight: '21px',
});

const InfoText = styled('p')({
  fontSize: 16,
  lineHeight: '21px',
  marginBottom: 40,
});

const StyledModalBody = styled(ModalBody)({
  color: colors.dark85,
  textAlign: "center",
  paddingTop: 50,
  paddingBottom: 50,
});

enum DownloadStates {
  'loading',
  'done',
  'error'
};

export const DownloadModal = ({ modalState }: { modalState: ModalStateReturn }) => {
  const [downloadState, setDownloadState] = useState(DownloadStates.loading);
  const myChartDataAccessContext = useContext(MyChartDataAccessContext);
  const auth = useAuth();
  const currentUser = auth?.currentUser;

  useEffect(() => {
    const timeout = setTimeout(() => {
      try {
        downloadPdf({
          chartProfileDisplayName:
            myChartDataAccessContext.currentlyViewingChartProfile?.displayName,
          currentUserName: currentUser?.displayName,
        });
        setDownloadState(DownloadStates.done);
      } catch (e) {
        setDownloadState(DownloadStates.error);
      }
    }, 3000);

    return () => clearTimeout(timeout);
  }, [
    myChartDataAccessContext.currentlyViewingChartProfile?.displayName,
    currentUser?.displayName]);

  const handleDownloadDoneClick = () => {
    modalState.hide();

    analytics.track({
      event: EVENTS.CLICK_DOWNLOAD_DONE_BTN,
    });
  }

  const renderBody = () => {
    switch (downloadState) {
      case DownloadStates.loading:
        return (
          <>
            <Headline>Preparing your PDF timeline</Headline>
            <InfoText>(Any hidden events will not be included.)</InfoText>
            <Loader />
          </>
        );
      case DownloadStates.done:
        return (
          <>
            <Headline>All set!</Headline>
            <InfoText>Your PDF should now be in your downloads.</InfoText>
            <Button color={colors.burgundy} onClick={handleDownloadDoneClick}>
              Done
            </Button>
          </>
        );
      case DownloadStates.error:
        return 'error!';
      default:
        return missingCaseError(downloadState);
    }
  }

  return (
    <Modal {...modalState} maxWidth={415}>
      <StyledModalBody>{renderBody()}</StyledModalBody>
    </Modal>
  );
};
