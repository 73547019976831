import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export enum ChartCategory {
  Diagnoses = 'DIAGNOSES',
  Hospitalizations = 'HOSPITALIZATIONS',
  InjuriesIllnesses = 'INJURIES_ILLNESSES',
  LifeEvents = 'LIFE_EVENTS',
  SubstanceUse = 'SUBSTANCE_USE',
  SurgeriesProcedures = 'SURGERIES_PROCEDURES',
  Symptoms = 'SYMPTOMS',
  TestsImaging = 'TESTS_IMAGING',
  TreatmentsSelfCare = 'TREATMENTS_SELF_CARE'
}

export type ChartData = {
  __typename?: 'ChartData';
  always: Scalars['Boolean'];
  chartCategory: ChartCategory;
  chartProfile: ChartProfile;
  chartProfileId: Scalars['Int'];
  current: Scalars['Boolean'];
  displayEndDay?: Maybe<Scalars['Int']>;
  displayEndMonth?: Maybe<Scalars['Int']>;
  displayEndYear?: Maybe<Scalars['Int']>;
  displayStartDay?: Maybe<Scalars['Int']>;
  displayStartMonth?: Maybe<Scalars['Int']>;
  displayStartYear?: Maybe<Scalars['Int']>;
  helped?: Maybe<DidItHelp>;
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  subCategory?: Maybe<SubCategory>;
  timestampEnd?: Maybe<Scalars['DateTime']>;
  timestampStart: Scalars['DateTime'];
  value?: Maybe<Scalars['Float']>;
};

export type ChartDataAccess = {
  __typename?: 'ChartDataAccess';
  aboutChartProfile: ChartProfile;
  accessType: ChartDataAccessType;
  accessorUser: User;
  id: Scalars['ID'];
};

export type ChartDataAccessEditInput = {
  aboutChartProfileId: Scalars['Int'];
  accessType: ChartDataAccessType;
  accessorUserId: Scalars['Int'];
  id: Scalars['Int'];
};

export type ChartDataAccessRemovalInput = {
  aboutChartProfileId: Scalars['Int'];
  accessorUserId: Scalars['Int'];
  id: Scalars['Int'];
};

export enum ChartDataAccessType {
  Owner = 'OWNER',
  Read = 'READ',
  SuperAdmin = 'SUPER_ADMIN',
  Write = 'WRITE'
}

export type ChartDataInputAdd = {
  always: Scalars['Boolean'];
  chartCategory: ChartCategory;
  chartProfileId: Scalars['Int'];
  current: Scalars['Boolean'];
  displayEndDay?: Maybe<Scalars['Int']>;
  displayEndMonth?: Maybe<Scalars['Int']>;
  displayEndYear?: Maybe<Scalars['Int']>;
  displayStartDay?: Maybe<Scalars['Int']>;
  displayStartMonth?: Maybe<Scalars['Int']>;
  displayStartYear?: Maybe<Scalars['Int']>;
  helped?: Maybe<DidItHelp>;
  label?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  subCategoryId?: Maybe<Scalars['Int']>;
  timestampEnd?: Maybe<Scalars['DateTime']>;
  timestampStart: Scalars['DateTime'];
  value?: Maybe<Scalars['Float']>;
};

export type ChartDataInputDelete = {
  chartProfileId: Scalars['Int'];
  id: Scalars['Int'];
};

export type ChartDataInputEdit = {
  always: Scalars['Boolean'];
  chartCategory: ChartCategory;
  chartProfileId: Scalars['Int'];
  current: Scalars['Boolean'];
  displayEndDay?: Maybe<Scalars['Int']>;
  displayEndMonth?: Maybe<Scalars['Int']>;
  displayEndYear?: Maybe<Scalars['Int']>;
  displayStartDay?: Maybe<Scalars['Int']>;
  displayStartMonth?: Maybe<Scalars['Int']>;
  displayStartYear?: Maybe<Scalars['Int']>;
  helped?: Maybe<DidItHelp>;
  id: Scalars['Int'];
  label?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  subCategoryId?: Maybe<Scalars['Int']>;
  timestampEnd?: Maybe<Scalars['DateTime']>;
  timestampStart: Scalars['DateTime'];
  value?: Maybe<Scalars['Float']>;
};

export type ChartProfile = {
  __typename?: 'ChartProfile';
  createdAt: Scalars['DateTime'];
  dateOfBirth: Scalars['DateTime'];
  displayName: Scalars['String'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type ChartProfileEditInput = {
  dateOfBirth: Scalars['DateTime'];
  displayName: Scalars['String'];
  id: Scalars['Int'];
};


export enum DidItHelp {
  No = 'NO',
  NotSure = 'NOT_SURE',
  SideEffects = 'SIDE_EFFECTS',
  Yes = 'YES'
}

export type Mutation = {
  __typename?: 'Mutation';
  addChartData: ChartData;
  addSubCategory: SubCategory;
  addUser: User;
  editChartData: ChartData;
  editChartDataAccess: ChartDataAccess;
  editChartProfile: ChartProfile;
  editSubCategory: SubCategory;
  editUser: User;
  removeChartData: ChartData;
  removeChartDataAccess: ChartDataAccess;
  removeSubCategory: SubCategory;
};


export type MutationAddChartDataArgs = {
  chartDataInput: ChartDataInputAdd;
};


export type MutationAddSubCategoryArgs = {
  subCategoryInput: SubCategoryInputAdd;
};


export type MutationAddUserArgs = {
  user: UserInput;
};


export type MutationEditChartDataArgs = {
  chartDataInput: ChartDataInputEdit;
};


export type MutationEditChartDataAccessArgs = {
  chartDataAccessInput: ChartDataAccessEditInput;
};


export type MutationEditChartProfileArgs = {
  chartProfile: ChartProfileEditInput;
};


export type MutationEditSubCategoryArgs = {
  subCategoryInput: SubCategoryInputEdit;
};


export type MutationEditUserArgs = {
  user: UserEditInput;
};


export type MutationRemoveChartDataArgs = {
  chartDataInput: ChartDataInputDelete;
};


export type MutationRemoveChartDataAccessArgs = {
  chartDataAccessRemoval: ChartDataAccessRemovalInput;
};


export type MutationRemoveSubCategoryArgs = {
  subCategoryInput: SubCategoryInputDelete;
};

export type Query = {
  __typename?: 'Query';
  chartDataAccessToChartProfile: Array<ChartDataAccess>;
  getChartDataForChartProfile: Array<ChartData>;
  getSubCategoriesForChartProfile: Array<SubCategory>;
  myChartDataAccess: Array<ChartDataAccess>;
  whoAmI?: Maybe<User>;
};


export type QueryChartDataAccessToChartProfileArgs = {
  aboutChartProfileId: Scalars['Int'];
};


export type QueryGetChartDataForChartProfileArgs = {
  chartProfileId: Scalars['Int'];
};


export type QueryGetSubCategoriesForChartProfileArgs = {
  chartProfileId: Scalars['Int'];
};


export type QueryMyChartDataAccessArgs = {
  asSuperAdmin: Scalars['Boolean'];
};

export type SubCategory = {
  __typename?: 'SubCategory';
  chartCategory: ChartCategory;
  chartProfileId: Scalars['Int'];
  color: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SubCategoryInputAdd = {
  chartCategory: ChartCategory;
  chartProfileId: Scalars['Int'];
  color: Scalars['String'];
  name: Scalars['String'];
};

export type SubCategoryInputDelete = {
  chartProfileId: Scalars['Int'];
  id: Scalars['Int'];
};

export type SubCategoryInputEdit = {
  chartCategory: ChartCategory;
  chartProfileId: Scalars['Int'];
  color: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  analyticsId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  dateOfBirth: Scalars['DateTime'];
  displayName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isSuperAdmin?: Maybe<Scalars['Boolean']>;
  signupCode?: Maybe<Scalars['String']>;
  username: Scalars['String'];
};

export type UserEditInput = {
  dateOfBirth: Scalars['DateTime'];
  displayName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

export type UserInput = {
  dateOfBirth: Scalars['DateTime'];
  displayName: Scalars['String'];
  email: Scalars['String'];
  signupCode?: Maybe<Scalars['String']>;
  username: Scalars['String'];
};

export type AddChartDataMutationVariables = Exact<{
  data: ChartDataInputAdd;
}>;


export type AddChartDataMutation = (
  { __typename?: 'Mutation' }
  & { addChartData: (
    { __typename?: 'ChartData' }
    & Pick<ChartData, 'id' | 'chartProfileId' | 'chartCategory' | 'label' | 'value' | 'notes' | 'helped' | 'timestampStart' | 'displayStartMonth' | 'displayStartDay' | 'displayStartYear' | 'timestampEnd' | 'displayEndMonth' | 'displayEndDay' | 'displayEndYear' | 'current' | 'always'>
    & { subCategory?: Maybe<(
      { __typename?: 'SubCategory' }
      & Pick<SubCategory, 'id' | 'name' | 'color'>
    )> }
  ) }
);

export type EditChartDataMutationVariables = Exact<{
  data: ChartDataInputEdit;
}>;


export type EditChartDataMutation = (
  { __typename?: 'Mutation' }
  & { editChartData: (
    { __typename?: 'ChartData' }
    & Pick<ChartData, 'id' | 'chartCategory' | 'label' | 'value' | 'notes' | 'helped' | 'timestampStart' | 'displayStartMonth' | 'displayStartDay' | 'displayStartYear' | 'timestampEnd' | 'displayEndMonth' | 'displayEndDay' | 'displayEndYear' | 'current' | 'always'>
    & { subCategory?: Maybe<(
      { __typename?: 'SubCategory' }
      & Pick<SubCategory, 'id' | 'name' | 'color'>
    )> }
  ) }
);

export type RemoveChartDataMutationVariables = Exact<{
  data: ChartDataInputDelete;
}>;


export type RemoveChartDataMutation = (
  { __typename?: 'Mutation' }
  & { removeChartData: (
    { __typename?: 'ChartData' }
    & Pick<ChartData, 'id'>
  ) }
);

export type AddUserMutationVariables = Exact<{
  user: UserInput;
}>;


export type AddUserMutation = (
  { __typename?: 'Mutation' }
  & { addUser: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type EditChartProfileMutationVariables = Exact<{
  chartProfile: ChartProfileEditInput;
}>;


export type EditChartProfileMutation = (
  { __typename?: 'Mutation' }
  & { editChartProfile: (
    { __typename?: 'ChartProfile' }
    & Pick<ChartProfile, 'id' | 'displayName' | 'dateOfBirth'>
  ) }
);

export type AddSubCategoryMutationVariables = Exact<{
  subCategoryInput: SubCategoryInputAdd;
}>;


export type AddSubCategoryMutation = (
  { __typename?: 'Mutation' }
  & { addSubCategory: (
    { __typename?: 'SubCategory' }
    & Pick<SubCategory, 'id' | 'chartProfileId' | 'name' | 'color' | 'chartCategory'>
  ) }
);

export type EditSubCategoryMutationVariables = Exact<{
  subCategoryInput: SubCategoryInputEdit;
}>;


export type EditSubCategoryMutation = (
  { __typename?: 'Mutation' }
  & { editSubCategory: (
    { __typename?: 'SubCategory' }
    & Pick<SubCategory, 'id' | 'chartProfileId' | 'name' | 'color' | 'chartCategory'>
  ) }
);

export type RemoveSubCategoryMutationVariables = Exact<{
  subCategoryInput: SubCategoryInputDelete;
}>;


export type RemoveSubCategoryMutation = (
  { __typename?: 'Mutation' }
  & { removeSubCategory: (
    { __typename?: 'SubCategory' }
    & Pick<SubCategory, 'id' | 'chartProfileId'>
  ) }
);

export type EditChartDataAccessMutationVariables = Exact<{
  chartDataAccessInput: ChartDataAccessEditInput;
}>;


export type EditChartDataAccessMutation = (
  { __typename?: 'Mutation' }
  & { editChartDataAccess: (
    { __typename?: 'ChartDataAccess' }
    & Pick<ChartDataAccess, 'id' | 'accessType'>
  ) }
);

export type RemoveChartDataAccessMutationVariables = Exact<{
  chartDataAccessRemoval: ChartDataAccessRemovalInput;
}>;


export type RemoveChartDataAccessMutation = (
  { __typename?: 'Mutation' }
  & { removeChartDataAccess: (
    { __typename?: 'ChartDataAccess' }
    & Pick<ChartDataAccess, 'id'>
  ) }
);

export type GetChartDataQueryVariables = Exact<{
  chartProfileId: Scalars['Int'];
}>;


export type GetChartDataQuery = (
  { __typename?: 'Query' }
  & { getChartDataForChartProfile: Array<(
    { __typename?: 'ChartData' }
    & Pick<ChartData, 'id' | 'chartCategory' | 'label' | 'value' | 'notes' | 'helped' | 'chartProfileId' | 'timestampStart' | 'displayStartMonth' | 'displayStartDay' | 'displayStartYear' | 'timestampEnd' | 'displayEndMonth' | 'displayEndDay' | 'displayEndYear' | 'current' | 'always'>
    & { subCategory?: Maybe<(
      { __typename?: 'SubCategory' }
      & Pick<SubCategory, 'id' | 'name' | 'color'>
    )> }
  )> }
);

export type MyChartDataAccessQueryVariables = Exact<{
  asSuperAdmin: Scalars['Boolean'];
}>;


export type MyChartDataAccessQuery = (
  { __typename?: 'Query' }
  & { myChartDataAccess: Array<(
    { __typename?: 'ChartDataAccess' }
    & Pick<ChartDataAccess, 'accessType'>
    & { aboutChartProfile: (
      { __typename?: 'ChartProfile' }
      & Pick<ChartProfile, 'id' | 'displayName' | 'dateOfBirth'>
    ) }
  )> }
);

export type WhoAmIQueryVariables = Exact<{ [key: string]: never; }>;


export type WhoAmIQuery = (
  { __typename?: 'Query' }
  & { whoAmI?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'displayName' | 'isSuperAdmin' | 'id' | 'analyticsId' | 'signupCode' | 'dateOfBirth' | 'createdAt'>
  )> }
);

export type GetSubCategoriesForChartProfileQueryVariables = Exact<{
  chartProfileId: Scalars['Int'];
}>;


export type GetSubCategoriesForChartProfileQuery = (
  { __typename?: 'Query' }
  & { getSubCategoriesForChartProfile: Array<(
    { __typename?: 'SubCategory' }
    & Pick<SubCategory, 'id' | 'name' | 'color' | 'chartCategory' | 'chartProfileId'>
  )> }
);

export type ChartDataAccessToChartProfileQueryVariables = Exact<{
  aboutChartProfileId: Scalars['Int'];
}>;


export type ChartDataAccessToChartProfileQuery = (
  { __typename?: 'Query' }
  & { chartDataAccessToChartProfile: Array<(
    { __typename?: 'ChartDataAccess' }
    & Pick<ChartDataAccess, 'id' | 'accessType'>
    & { accessorUser: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName' | 'dateOfBirth' | 'email'>
    ), aboutChartProfile: (
      { __typename?: 'ChartProfile' }
      & Pick<ChartProfile, 'id'>
    ) }
  )> }
);


export const AddChartDataDocument = gql`
    mutation AddChartData($data: ChartDataInputAdd!) {
  addChartData(chartDataInput: $data) {
    id
    chartProfileId
    chartCategory
    label
    value
    notes
    helped
    subCategory {
      id
      name
      color
    }
    timestampStart
    displayStartMonth
    displayStartDay
    displayStartYear
    timestampEnd
    displayEndMonth
    displayEndDay
    displayEndYear
    current
    always
  }
}
    `;
export type AddChartDataMutationFn = Apollo.MutationFunction<AddChartDataMutation, AddChartDataMutationVariables>;

/**
 * __useAddChartDataMutation__
 *
 * To run a mutation, you first call `useAddChartDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddChartDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addChartDataMutation, { data, loading, error }] = useAddChartDataMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddChartDataMutation(baseOptions?: Apollo.MutationHookOptions<AddChartDataMutation, AddChartDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddChartDataMutation, AddChartDataMutationVariables>(AddChartDataDocument, options);
      }
export type AddChartDataMutationHookResult = ReturnType<typeof useAddChartDataMutation>;
export type AddChartDataMutationResult = Apollo.MutationResult<AddChartDataMutation>;
export type AddChartDataMutationOptions = Apollo.BaseMutationOptions<AddChartDataMutation, AddChartDataMutationVariables>;
export const EditChartDataDocument = gql`
    mutation EditChartData($data: ChartDataInputEdit!) {
  editChartData(chartDataInput: $data) {
    id
    chartCategory
    label
    value
    notes
    helped
    subCategory {
      id
      name
      color
    }
    timestampStart
    displayStartMonth
    displayStartDay
    displayStartYear
    timestampEnd
    displayEndMonth
    displayEndDay
    displayEndYear
    current
    always
  }
}
    `;
export type EditChartDataMutationFn = Apollo.MutationFunction<EditChartDataMutation, EditChartDataMutationVariables>;

/**
 * __useEditChartDataMutation__
 *
 * To run a mutation, you first call `useEditChartDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditChartDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editChartDataMutation, { data, loading, error }] = useEditChartDataMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditChartDataMutation(baseOptions?: Apollo.MutationHookOptions<EditChartDataMutation, EditChartDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditChartDataMutation, EditChartDataMutationVariables>(EditChartDataDocument, options);
      }
export type EditChartDataMutationHookResult = ReturnType<typeof useEditChartDataMutation>;
export type EditChartDataMutationResult = Apollo.MutationResult<EditChartDataMutation>;
export type EditChartDataMutationOptions = Apollo.BaseMutationOptions<EditChartDataMutation, EditChartDataMutationVariables>;
export const RemoveChartDataDocument = gql`
    mutation RemoveChartData($data: ChartDataInputDelete!) {
  removeChartData(chartDataInput: $data) {
    id
  }
}
    `;
export type RemoveChartDataMutationFn = Apollo.MutationFunction<RemoveChartDataMutation, RemoveChartDataMutationVariables>;

/**
 * __useRemoveChartDataMutation__
 *
 * To run a mutation, you first call `useRemoveChartDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveChartDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeChartDataMutation, { data, loading, error }] = useRemoveChartDataMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveChartDataMutation(baseOptions?: Apollo.MutationHookOptions<RemoveChartDataMutation, RemoveChartDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveChartDataMutation, RemoveChartDataMutationVariables>(RemoveChartDataDocument, options);
      }
export type RemoveChartDataMutationHookResult = ReturnType<typeof useRemoveChartDataMutation>;
export type RemoveChartDataMutationResult = Apollo.MutationResult<RemoveChartDataMutation>;
export type RemoveChartDataMutationOptions = Apollo.BaseMutationOptions<RemoveChartDataMutation, RemoveChartDataMutationVariables>;
export const AddUserDocument = gql`
    mutation AddUser($user: UserInput!) {
  addUser(user: $user) {
    id
  }
}
    `;
export type AddUserMutationFn = Apollo.MutationFunction<AddUserMutation, AddUserMutationVariables>;

/**
 * __useAddUserMutation__
 *
 * To run a mutation, you first call `useAddUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMutation, { data, loading, error }] = useAddUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useAddUserMutation(baseOptions?: Apollo.MutationHookOptions<AddUserMutation, AddUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserMutation, AddUserMutationVariables>(AddUserDocument, options);
      }
export type AddUserMutationHookResult = ReturnType<typeof useAddUserMutation>;
export type AddUserMutationResult = Apollo.MutationResult<AddUserMutation>;
export type AddUserMutationOptions = Apollo.BaseMutationOptions<AddUserMutation, AddUserMutationVariables>;
export const EditChartProfileDocument = gql`
    mutation EditChartProfile($chartProfile: ChartProfileEditInput!) {
  editChartProfile(chartProfile: $chartProfile) {
    id
    displayName
    dateOfBirth
  }
}
    `;
export type EditChartProfileMutationFn = Apollo.MutationFunction<EditChartProfileMutation, EditChartProfileMutationVariables>;

/**
 * __useEditChartProfileMutation__
 *
 * To run a mutation, you first call `useEditChartProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditChartProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editChartProfileMutation, { data, loading, error }] = useEditChartProfileMutation({
 *   variables: {
 *      chartProfile: // value for 'chartProfile'
 *   },
 * });
 */
export function useEditChartProfileMutation(baseOptions?: Apollo.MutationHookOptions<EditChartProfileMutation, EditChartProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditChartProfileMutation, EditChartProfileMutationVariables>(EditChartProfileDocument, options);
      }
export type EditChartProfileMutationHookResult = ReturnType<typeof useEditChartProfileMutation>;
export type EditChartProfileMutationResult = Apollo.MutationResult<EditChartProfileMutation>;
export type EditChartProfileMutationOptions = Apollo.BaseMutationOptions<EditChartProfileMutation, EditChartProfileMutationVariables>;
export const AddSubCategoryDocument = gql`
    mutation AddSubCategory($subCategoryInput: SubCategoryInputAdd!) {
  addSubCategory(subCategoryInput: $subCategoryInput) {
    id
    chartProfileId
    name
    color
    chartCategory
  }
}
    `;
export type AddSubCategoryMutationFn = Apollo.MutationFunction<AddSubCategoryMutation, AddSubCategoryMutationVariables>;

/**
 * __useAddSubCategoryMutation__
 *
 * To run a mutation, you first call `useAddSubCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSubCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSubCategoryMutation, { data, loading, error }] = useAddSubCategoryMutation({
 *   variables: {
 *      subCategoryInput: // value for 'subCategoryInput'
 *   },
 * });
 */
export function useAddSubCategoryMutation(baseOptions?: Apollo.MutationHookOptions<AddSubCategoryMutation, AddSubCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSubCategoryMutation, AddSubCategoryMutationVariables>(AddSubCategoryDocument, options);
      }
export type AddSubCategoryMutationHookResult = ReturnType<typeof useAddSubCategoryMutation>;
export type AddSubCategoryMutationResult = Apollo.MutationResult<AddSubCategoryMutation>;
export type AddSubCategoryMutationOptions = Apollo.BaseMutationOptions<AddSubCategoryMutation, AddSubCategoryMutationVariables>;
export const EditSubCategoryDocument = gql`
    mutation EditSubCategory($subCategoryInput: SubCategoryInputEdit!) {
  editSubCategory(subCategoryInput: $subCategoryInput) {
    id
    chartProfileId
    name
    color
    chartCategory
  }
}
    `;
export type EditSubCategoryMutationFn = Apollo.MutationFunction<EditSubCategoryMutation, EditSubCategoryMutationVariables>;

/**
 * __useEditSubCategoryMutation__
 *
 * To run a mutation, you first call `useEditSubCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSubCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSubCategoryMutation, { data, loading, error }] = useEditSubCategoryMutation({
 *   variables: {
 *      subCategoryInput: // value for 'subCategoryInput'
 *   },
 * });
 */
export function useEditSubCategoryMutation(baseOptions?: Apollo.MutationHookOptions<EditSubCategoryMutation, EditSubCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditSubCategoryMutation, EditSubCategoryMutationVariables>(EditSubCategoryDocument, options);
      }
export type EditSubCategoryMutationHookResult = ReturnType<typeof useEditSubCategoryMutation>;
export type EditSubCategoryMutationResult = Apollo.MutationResult<EditSubCategoryMutation>;
export type EditSubCategoryMutationOptions = Apollo.BaseMutationOptions<EditSubCategoryMutation, EditSubCategoryMutationVariables>;
export const RemoveSubCategoryDocument = gql`
    mutation RemoveSubCategory($subCategoryInput: SubCategoryInputDelete!) {
  removeSubCategory(subCategoryInput: $subCategoryInput) {
    id
    chartProfileId
  }
}
    `;
export type RemoveSubCategoryMutationFn = Apollo.MutationFunction<RemoveSubCategoryMutation, RemoveSubCategoryMutationVariables>;

/**
 * __useRemoveSubCategoryMutation__
 *
 * To run a mutation, you first call `useRemoveSubCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSubCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSubCategoryMutation, { data, loading, error }] = useRemoveSubCategoryMutation({
 *   variables: {
 *      subCategoryInput: // value for 'subCategoryInput'
 *   },
 * });
 */
export function useRemoveSubCategoryMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSubCategoryMutation, RemoveSubCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveSubCategoryMutation, RemoveSubCategoryMutationVariables>(RemoveSubCategoryDocument, options);
      }
export type RemoveSubCategoryMutationHookResult = ReturnType<typeof useRemoveSubCategoryMutation>;
export type RemoveSubCategoryMutationResult = Apollo.MutationResult<RemoveSubCategoryMutation>;
export type RemoveSubCategoryMutationOptions = Apollo.BaseMutationOptions<RemoveSubCategoryMutation, RemoveSubCategoryMutationVariables>;
export const EditChartDataAccessDocument = gql`
    mutation EditChartDataAccess($chartDataAccessInput: ChartDataAccessEditInput!) {
  editChartDataAccess(chartDataAccessInput: $chartDataAccessInput) {
    id
    accessType
  }
}
    `;
export type EditChartDataAccessMutationFn = Apollo.MutationFunction<EditChartDataAccessMutation, EditChartDataAccessMutationVariables>;

/**
 * __useEditChartDataAccessMutation__
 *
 * To run a mutation, you first call `useEditChartDataAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditChartDataAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editChartDataAccessMutation, { data, loading, error }] = useEditChartDataAccessMutation({
 *   variables: {
 *      chartDataAccessInput: // value for 'chartDataAccessInput'
 *   },
 * });
 */
export function useEditChartDataAccessMutation(baseOptions?: Apollo.MutationHookOptions<EditChartDataAccessMutation, EditChartDataAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditChartDataAccessMutation, EditChartDataAccessMutationVariables>(EditChartDataAccessDocument, options);
      }
export type EditChartDataAccessMutationHookResult = ReturnType<typeof useEditChartDataAccessMutation>;
export type EditChartDataAccessMutationResult = Apollo.MutationResult<EditChartDataAccessMutation>;
export type EditChartDataAccessMutationOptions = Apollo.BaseMutationOptions<EditChartDataAccessMutation, EditChartDataAccessMutationVariables>;
export const RemoveChartDataAccessDocument = gql`
    mutation RemoveChartDataAccess($chartDataAccessRemoval: ChartDataAccessRemovalInput!) {
  removeChartDataAccess(chartDataAccessRemoval: $chartDataAccessRemoval) {
    id
  }
}
    `;
export type RemoveChartDataAccessMutationFn = Apollo.MutationFunction<RemoveChartDataAccessMutation, RemoveChartDataAccessMutationVariables>;

/**
 * __useRemoveChartDataAccessMutation__
 *
 * To run a mutation, you first call `useRemoveChartDataAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveChartDataAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeChartDataAccessMutation, { data, loading, error }] = useRemoveChartDataAccessMutation({
 *   variables: {
 *      chartDataAccessRemoval: // value for 'chartDataAccessRemoval'
 *   },
 * });
 */
export function useRemoveChartDataAccessMutation(baseOptions?: Apollo.MutationHookOptions<RemoveChartDataAccessMutation, RemoveChartDataAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveChartDataAccessMutation, RemoveChartDataAccessMutationVariables>(RemoveChartDataAccessDocument, options);
      }
export type RemoveChartDataAccessMutationHookResult = ReturnType<typeof useRemoveChartDataAccessMutation>;
export type RemoveChartDataAccessMutationResult = Apollo.MutationResult<RemoveChartDataAccessMutation>;
export type RemoveChartDataAccessMutationOptions = Apollo.BaseMutationOptions<RemoveChartDataAccessMutation, RemoveChartDataAccessMutationVariables>;
export const GetChartDataDocument = gql`
    query GetChartData($chartProfileId: Int!) {
  getChartDataForChartProfile(chartProfileId: $chartProfileId) {
    id
    chartCategory
    label
    value
    notes
    helped
    subCategory {
      id
      name
      color
    }
    chartProfileId
    timestampStart
    displayStartMonth
    displayStartDay
    displayStartYear
    timestampEnd
    displayEndMonth
    displayEndDay
    displayEndYear
    current
    always
  }
}
    `;

/**
 * __useGetChartDataQuery__
 *
 * To run a query within a React component, call `useGetChartDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChartDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChartDataQuery({
 *   variables: {
 *      chartProfileId: // value for 'chartProfileId'
 *   },
 * });
 */
export function useGetChartDataQuery(baseOptions: Apollo.QueryHookOptions<GetChartDataQuery, GetChartDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChartDataQuery, GetChartDataQueryVariables>(GetChartDataDocument, options);
      }
export function useGetChartDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChartDataQuery, GetChartDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChartDataQuery, GetChartDataQueryVariables>(GetChartDataDocument, options);
        }
export type GetChartDataQueryHookResult = ReturnType<typeof useGetChartDataQuery>;
export type GetChartDataLazyQueryHookResult = ReturnType<typeof useGetChartDataLazyQuery>;
export type GetChartDataQueryResult = Apollo.QueryResult<GetChartDataQuery, GetChartDataQueryVariables>;
export const MyChartDataAccessDocument = gql`
    query MyChartDataAccess($asSuperAdmin: Boolean!) {
  myChartDataAccess(asSuperAdmin: $asSuperAdmin) {
    accessType
    aboutChartProfile {
      id
      displayName
      dateOfBirth
    }
  }
}
    `;

/**
 * __useMyChartDataAccessQuery__
 *
 * To run a query within a React component, call `useMyChartDataAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyChartDataAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyChartDataAccessQuery({
 *   variables: {
 *      asSuperAdmin: // value for 'asSuperAdmin'
 *   },
 * });
 */
export function useMyChartDataAccessQuery(baseOptions: Apollo.QueryHookOptions<MyChartDataAccessQuery, MyChartDataAccessQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyChartDataAccessQuery, MyChartDataAccessQueryVariables>(MyChartDataAccessDocument, options);
      }
export function useMyChartDataAccessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyChartDataAccessQuery, MyChartDataAccessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyChartDataAccessQuery, MyChartDataAccessQueryVariables>(MyChartDataAccessDocument, options);
        }
export type MyChartDataAccessQueryHookResult = ReturnType<typeof useMyChartDataAccessQuery>;
export type MyChartDataAccessLazyQueryHookResult = ReturnType<typeof useMyChartDataAccessLazyQuery>;
export type MyChartDataAccessQueryResult = Apollo.QueryResult<MyChartDataAccessQuery, MyChartDataAccessQueryVariables>;
export const WhoAmIDocument = gql`
    query WhoAmI {
  whoAmI {
    displayName
    isSuperAdmin
    id
    analyticsId
    signupCode
    dateOfBirth
    createdAt
  }
}
    `;

/**
 * __useWhoAmIQuery__
 *
 * To run a query within a React component, call `useWhoAmIQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhoAmIQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhoAmIQuery({
 *   variables: {
 *   },
 * });
 */
export function useWhoAmIQuery(baseOptions?: Apollo.QueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WhoAmIQuery, WhoAmIQueryVariables>(WhoAmIDocument, options);
      }
export function useWhoAmILazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WhoAmIQuery, WhoAmIQueryVariables>(WhoAmIDocument, options);
        }
export type WhoAmIQueryHookResult = ReturnType<typeof useWhoAmIQuery>;
export type WhoAmILazyQueryHookResult = ReturnType<typeof useWhoAmILazyQuery>;
export type WhoAmIQueryResult = Apollo.QueryResult<WhoAmIQuery, WhoAmIQueryVariables>;
export const GetSubCategoriesForChartProfileDocument = gql`
    query GetSubCategoriesForChartProfile($chartProfileId: Int!) {
  getSubCategoriesForChartProfile(chartProfileId: $chartProfileId) {
    id
    name
    color
    chartCategory
    chartProfileId
  }
}
    `;

/**
 * __useGetSubCategoriesForChartProfileQuery__
 *
 * To run a query within a React component, call `useGetSubCategoriesForChartProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubCategoriesForChartProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubCategoriesForChartProfileQuery({
 *   variables: {
 *      chartProfileId: // value for 'chartProfileId'
 *   },
 * });
 */
export function useGetSubCategoriesForChartProfileQuery(baseOptions: Apollo.QueryHookOptions<GetSubCategoriesForChartProfileQuery, GetSubCategoriesForChartProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubCategoriesForChartProfileQuery, GetSubCategoriesForChartProfileQueryVariables>(GetSubCategoriesForChartProfileDocument, options);
      }
export function useGetSubCategoriesForChartProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubCategoriesForChartProfileQuery, GetSubCategoriesForChartProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubCategoriesForChartProfileQuery, GetSubCategoriesForChartProfileQueryVariables>(GetSubCategoriesForChartProfileDocument, options);
        }
export type GetSubCategoriesForChartProfileQueryHookResult = ReturnType<typeof useGetSubCategoriesForChartProfileQuery>;
export type GetSubCategoriesForChartProfileLazyQueryHookResult = ReturnType<typeof useGetSubCategoriesForChartProfileLazyQuery>;
export type GetSubCategoriesForChartProfileQueryResult = Apollo.QueryResult<GetSubCategoriesForChartProfileQuery, GetSubCategoriesForChartProfileQueryVariables>;
export const ChartDataAccessToChartProfileDocument = gql`
    query ChartDataAccessToChartProfile($aboutChartProfileId: Int!) {
  chartDataAccessToChartProfile(aboutChartProfileId: $aboutChartProfileId) {
    id
    accessType
    accessorUser {
      id
      displayName
      dateOfBirth
      email
    }
    aboutChartProfile {
      id
    }
  }
}
    `;

/**
 * __useChartDataAccessToChartProfileQuery__
 *
 * To run a query within a React component, call `useChartDataAccessToChartProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useChartDataAccessToChartProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChartDataAccessToChartProfileQuery({
 *   variables: {
 *      aboutChartProfileId: // value for 'aboutChartProfileId'
 *   },
 * });
 */
export function useChartDataAccessToChartProfileQuery(baseOptions: Apollo.QueryHookOptions<ChartDataAccessToChartProfileQuery, ChartDataAccessToChartProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChartDataAccessToChartProfileQuery, ChartDataAccessToChartProfileQueryVariables>(ChartDataAccessToChartProfileDocument, options);
      }
export function useChartDataAccessToChartProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChartDataAccessToChartProfileQuery, ChartDataAccessToChartProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChartDataAccessToChartProfileQuery, ChartDataAccessToChartProfileQueryVariables>(ChartDataAccessToChartProfileDocument, options);
        }
export type ChartDataAccessToChartProfileQueryHookResult = ReturnType<typeof useChartDataAccessToChartProfileQuery>;
export type ChartDataAccessToChartProfileLazyQueryHookResult = ReturnType<typeof useChartDataAccessToChartProfileLazyQuery>;
export type ChartDataAccessToChartProfileQueryResult = Apollo.QueryResult<ChartDataAccessToChartProfileQuery, ChartDataAccessToChartProfileQueryVariables>;