import React, { useEffect, useMemo, useState } from "react";
import { useDisclosureState } from "reakit";
import styled from "styled-components";
import { transparentize } from "polished";
import {
  isValid as checkIfValidDate,
  parse,
  differenceInYears,
} from "date-fns";
import enUS from "date-fns/locale/en-US";

import { colors } from '../constants/theme';
import {
  MyChartDataAccessContext, MyChartDataAccessChartProfile,
  ownsChartProfile,
  canEditChartProfile,
} from "../context/MyChartDataAccessContext";
// import { Link } from '@reach/router';
import { AddData } from "./timeline/AddData";
import { DownloadButton } from "./DownloadButton";
import { FlyoutDisclosure, FlyoutMenu, navHorizontalPadding } from "./FlyoutMenu";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalHeading, ModalStateReturn, useModalState } from "./base/Modal";
import { Field } from "./timeline/DataModal/Field";
import { Label } from "./timeline/DataModal/Label";
import { Input } from "./base/Input";
import { DateInputValues, DateInputs } from "./timeline/DataModal/DateInputs";
import { Button } from "./base/Button";
import { FieldError } from "./timeline/DataModal/EditSymptoms";
import { ChartDataAccessType, useEditChartProfileMutation } from "../data/graphql/generated/graphql";
import { convertDateToDisplayStartValues, generateDateFromValues, generateEstimatedDateFromValues } from "../helpers/dates";
import { uiConstants } from "../uiConstants";
import { ShareButton } from "./ShareButton";
import { Pill } from "./base/Pill";
import { AccessIcon } from "./base/AccessIcon";
import { Icon } from "./base/Icon";

const StickyHeader = styled("header")`
  width: 100%;
  height: ${uiConstants.navbar.height}px;
  position: fixed;
  top: 0;
  z-index: 3;
  font-family: Graphik Medium, sans-serif;
  background-color: ${transparentize(0.08, colors.dark)};
  display: flex;
  justify-content: space-between;
  padding: 0 ${navHorizontalPadding}px;
  box-sizing: border-box;
  color: ${colors.white};
`;
const ChartProfileName = styled("div")<{ isClickable?: boolean}>(({ isClickable }) => ({
  fontSize: 15,
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  ...(isClickable && ({
    cursor: 'pointer',
  }))
}));

const ChartProfileAge = styled("span")`
  font-family: Graphik Regular, sans-serif;
  font-size: 14px;
  margin-left: 10px;
`;

const ChartProfileNameWrapper = styled("div")({
  display: "flex",
  gap: 16,
  alignItems: "center",
});

const Nav = styled("nav")({
  display: "flex",
  alignItems: "center",
  height: uiConstants.navbar.height,
  gap: 5,
});

const ButtonNav = styled(Nav)({
  gap: 10,
});

const EditNameModal = ({
  modalState,
  currentlyViewingChartProfile,
}: {
  modalState: ModalStateReturn;
  currentlyViewingChartProfile?: MyChartDataAccessChartProfile | null;
}) => {
  const defaultInfo = {
    name: {
      valid: true,
      value: "",
    },
    dateOfBirth: {
      valid: true,
      value: {
        year: "",
        month: "",
        day: "",
      },
    },
  };
  const [state, setState] = useState(defaultInfo);
  useEffect(() => {
    const dateOfBirth = new Date(currentlyViewingChartProfile?.dateOfBirth);
    const name = currentlyViewingChartProfile?.displayName;
    const {
      displayYear,
      displayMonth,
      displayDay,
    } = convertDateToDisplayStartValues(dateOfBirth);
    setState((newState) => ({
      ...newState,
      ...(name && {
        name: {
          ...newState.name,
          value: name,
        },
      }),
      ...(dateOfBirth && {
        dateOfBirth: {
          ...newState.dateOfBirth,
          value: {
            year: displayYear.toString(),
            month: displayMonth.toString(),
            day: displayDay.toString(),
          },
        },
      }),
    }));
  }, [currentlyViewingChartProfile]);

  const [editChartProfileMutation] = useEditChartProfileMutation();

  const handleChange =
    (field: keyof typeof defaultInfo) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setState({
        ...state,
        [field]: {
          valid: !!e.target.value,
          value: e.target.value,
        },
      });
    };
  const handleDateOfBirthChange = (newDateOfBirthValues: DateInputValues) => {
    const constructedDateString =
      generateEstimatedDateFromValues(newDateOfBirthValues);
    // Handles tricky validation like 10/32/2023
    const parsedDate = parse(constructedDateString, "P", new Date(), {
      locale: enUS,
    });

    setState({
      ...state,
      dateOfBirth: {
        valid:
          !!newDateOfBirthValues.year &&
          !!newDateOfBirthValues.month &&
          !!newDateOfBirthValues.day &&
          checkIfValidDate(parsedDate),
        value: newDateOfBirthValues,
      },
    });
  };

  const isValid = useMemo(
    () => state.name.valid && state.dateOfBirth.valid,
    [state.name.valid, state.dateOfBirth.valid]
  );

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (isValid && currentlyViewingChartProfile?.id) {
      const mutation = await editChartProfileMutation({
        variables: {
          chartProfile: {
            id: parseInt(currentlyViewingChartProfile?.id, 10),
            displayName: state.name.value,
            dateOfBirth: generateDateFromValues(state.dateOfBirth.value),
          },
        }
      });
      if (!mutation.errors) modalState.hide();
    }
  };

  if (!modalState.visible) {
    return null;
  }

  return (
    <Modal {...modalState}>
      <form onSubmit={handleSubmit}>
        <ModalHeader {...modalState}>
          <ModalHeading>Edit details</ModalHeading>
        </ModalHeader>
        <ModalBody {...modalState}>
          <Field withLabel={true} vertical={true}>
            <Label>Display Name</Label>
            <Input
              type="name"
              value={state.name.value}
              onChange={handleChange("name")}
            />
            {!state.name.valid && <FieldError>Display Name is required</FieldError>}
          </Field>{" "}
          <Field withLabel={true} vertical={true}>
            <Label>Birthdate</Label>
            <DateInputs
              displayValues={state.dateOfBirth.value}
              dateInputRequired={{
                year: true,
                month: true,
                day: true,
              }}
              onChange={handleDateOfBirthChange}
              error={state.dateOfBirth.valid ? undefined : "Invalid date"}
            />
          </Field>
        </ModalBody>
        <ModalFooter>
          <Button
            color={colors.burgundy}
            type="submit"
            disabled={!isValid}
            tabIndex={0}
          >
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export const NavBar = () => {
  const myChartDataAccessContext = React.useContext(MyChartDataAccessContext);
  const flyoutState = useDisclosureState();
  const currentlyViewingChartProfileDisplayName =
    myChartDataAccessContext.currentlyViewingChartProfile?.displayName;
  const currentlyViewingDateOfBirth =
    new Date(myChartDataAccessContext.currentlyViewingChartProfile?.dateOfBirth);
  const currentlyViewingChartProfileAge = differenceInYears(
    new Date(),
    currentlyViewingDateOfBirth
  );

  // Only the owner of a chart profile should be able to modify its info (name, dob)
  const canEditChartProfileInfo = ownsChartProfile(myChartDataAccessContext);
  // Anyone who can edit it can modify the data
  const canEditChartProfileData = canEditChartProfile(myChartDataAccessContext);
  const modalState = useModalState();
  const [hasHoveredChartProfilename, setHasHoveredChartProfilename] = useState(false);

  return (
    <>
      <StickyHeader>
        <ChartProfileNameWrapper>
          <FlyoutDisclosure {...flyoutState}>
            <Icon name="menu" style={{ fontSize: 44 }} />
          </FlyoutDisclosure>
          {currentlyViewingChartProfileDisplayName && (
            <>
              <ChartProfileName
                {...(canEditChartProfileInfo && {
                  onClick: () => {
                    modalState.show();
                    setHasHoveredChartProfilename(false);
                  },
                  isClickable: true,
                  onMouseEnter: () => setHasHoveredChartProfilename(true),
                  onMouseLeave: () => setHasHoveredChartProfilename(false),
                })}
              >
                <span>
                  {currentlyViewingChartProfileDisplayName}
                  {typeof currentlyViewingChartProfileAge === "number" && (
                    <ChartProfileAge>
                      Age: {currentlyViewingChartProfileAge}
                    </ChartProfileAge>
                  )}
                </span>
                {canEditChartProfileInfo && hasHoveredChartProfilename && (
                  <Icon name="stylus" />
                )}
              </ChartProfileName>
              {!canEditChartProfileData && (
                <Pill $color={colors.light90} $backgroundColor={colors.dark75}>
                  <AccessIcon accessType={ChartDataAccessType.Read} />
                  view only
                </Pill>
              )}
            </>
          )}
        </ChartProfileNameWrapper>
        <Nav>
          {/* <Link to='/timeline'>Timeline</Link>
          <Link to='/summary'>Summary</Link> */}
        </Nav>
        <Nav>
          <ButtonNav>
            {canEditChartProfileData ? (
              <>
                <AddData />
                <ShareButton />
              </>
            ) : (
              <DownloadButton />
            )}
          </ButtonNav>
        </Nav>
      </StickyHeader>
      <FlyoutMenu flyoutState={flyoutState} />
      {modalState.visible && (
        <EditNameModal
          modalState={modalState}
          currentlyViewingChartProfile={
            myChartDataAccessContext.currentlyViewingChartProfile
          }
        />
      )}
    </>
  );
};
