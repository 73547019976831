import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { gql } from "@apollo/client";
import styled from "styled-components";
import { useRadioState } from "reakit/Radio";
import { useMenuState, MenuButton } from "reakit/Menu";
import { isValid as checkIfValidDate, isBefore, parse } from "date-fns";
import enUS from "date-fns/locale/en-US";

import {
  ChartCategory,
  ChartDataInputAdd,
  ChartDataInputDelete,
  ChartDataInputEdit,
  DidItHelp,
  GetSubCategoriesForChartProfileQuery,
  SubCategory,
  useAddChartDataMutation,
  useEditChartDataMutation,
  useRemoveChartDataMutation,
} from "../../data/graphql/generated/graphql";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalStateReturn } from "../base/Modal";
import { colors } from "../../constants/theme";
import { ChartConfigType, ChartDataDisplay, getChartConfig, getTransparentColor } from "./config";
import { Checkbox } from "reakit";

import { Button } from "../base/Button";
import { NeutralButton } from "../base/NeutralButton";
import { DropdownMenu, DropdownMenuItem } from "../base/DropdownMenu";
import {
  Select,
  useSelectState,
  SelectTrigger,
  SelectOption,
  StyledSelectOption,
} from "../base/Select";
import { Field } from "./DataModal/Field";
import { Label } from "./DataModal/Label";
import { DataModalSliderInput } from "./DataModal/DataModalSliderInput";
import { ThreeButtonsField } from "./DataModal/ThreeButtonsField";
import { MyChartDataAccessContext, canEditChartProfile } from "../../context/MyChartDataAccessContext";
import { Input, InputSize } from "../base/Input";
import { Textarea } from "../base/Textarea";
import { DateInputValues, DateInputs } from "./DataModal/DateInputs";
import {
  getEstimatedValues,
  generateEstimatedDateFromValues,
} from "../../helpers/dates";
import { useDisplayData } from "./Timeline/DisplayDataContext";
import { missingCaseError } from "../../helpers/missingCaseError";
import { EditSymptoms, FieldError } from "./DataModal/EditSymptoms";
import { useMedia } from "react-use";
import { analytics } from "../../analytics";
import { EVENTS } from "../../analytics/events";
import { getDatesDisplay } from "./Labels";
import { Icon } from "../base/Icon";

const HeaderWrap = styled("div")({
  display: "flex",
});

export const chartCategoryConfig: ChartConfigType[] = [
  getChartConfig(ChartCategory.Diagnoses),
  getChartConfig(ChartCategory.Hospitalizations),
  getChartConfig(ChartCategory.InjuriesIllnesses),
  getChartConfig(ChartCategory.LifeEvents),
  getChartConfig(ChartCategory.SubstanceUse),
  getChartConfig(ChartCategory.SurgeriesProcedures),
  getChartConfig(ChartCategory.Symptoms),
  getChartConfig(ChartCategory.TestsImaging),
  getChartConfig(ChartCategory.TreatmentsSelfCare),
];

const Dot = styled("div")(
  ({ backgroundColor }: { backgroundColor: string }) => ({
    width: 12,
    height: 12,
    borderRadius: "100%",
    backgroundColor,
  })
);

const StyledCategoryOption = styled('span')({
  color: colors.dark75,
  display: 'flex',
  alignItems: 'center',
});

export const CategorySelectedOption = ({
  option,
  isSelectable = false,
}: {
  option: ChartConfigType;
  isSelectable?: boolean;
}) => (
  <>
    <Dot backgroundColor={getTransparentColor(option.colors.fill)} />
    <StyledCategoryOption>
      {option.text.singular}{" "}
      {isSelectable && <Icon name="keyboard_arrow_down" />}
    </StyledCategoryOption>
  </>
);

const SubCategorySelectedOption = ({
  option,
  isSelectable = false,
}: {
  option: {
    name: string;
    color: string;
  };
  isSelectable?: boolean;
}) => (
  <>
    <Dot backgroundColor={option.color} />
    <StyledCategoryOption>
      {option.name} {isSelectable && <Icon name="keyboard_arrow_down" />}
    </StyledCategoryOption>
  </>
);

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
type ChartDataData = PartialBy<ChartDataDisplay, "id">;
export interface OptionalChartDataData extends Partial<ChartDataData> {}

type ModalSubCategory = Pick<SubCategory, "name" | "color" | "id"> | undefined | null;

type ChartData = {
  id?: string;
  chartProfileId?: number;
  label?: string;
  notes?: string;
  value: number;
  category: ChartConfigType;
  subCategory?: ModalSubCategory;
  dateStart: Date | null;
  dateEnd?: Date | null;
  dateCurrent?: boolean;
  helped?: DidItHelp;
  displayStartMonth?: number | null;
  displayStartDay?: number | null;
  displayStartYear?: number | null;
  displayEndMonth?: number | null;
  displayEndDay?: number | null;
  displayEndYear?: number | null;
};

export const defaultData = {
  label: "",
  notes: "",
  value: 1,
  category: getChartConfig(ChartCategory.LifeEvents),
  dateStart: null,
  dateEnd: undefined,
  helped: DidItHelp.NotSure,
};

const getDefaultSubCategory = ({
  category,
  existingSubCategory,
  subCategories,
}: {
  category: ChartConfigType;
  existingSubCategory?: ModalSubCategory;
  subCategories?: GetSubCategoriesForChartProfileQuery["getSubCategoriesForChartProfile"];
}) => {
  let subCategory = undefined;

  // Right now only symptoms have subcategories
  if (category.canUseSubCategories) {
    subCategory =
      existingSubCategory ||
      (subCategories && subCategories.length ? subCategories[0] : undefined);
  }

  return subCategory;
};

const getChartData = ({
  chartData,
  subCategories,
}: {
  chartData?: OptionalChartDataData;
  subCategories?: GetSubCategoriesForChartProfileQuery["getSubCategoriesForChartProfile"];
}): ChartData => {
  if (!chartData) {
    return { ...defaultData };
  }

  const category = chartData.chartCategory
    ? getChartConfig(chartData.chartCategory)
    : defaultData.category;
  const defaultHelped =
    category.category === ChartCategory.Diagnoses
      ? DidItHelp.Yes
      : DidItHelp.NotSure;

  const isEditing = chartData && chartData.id;
  const id = isEditing ? chartData.id : undefined;

  const subCategory = getDefaultSubCategory({
    category,
    existingSubCategory: chartData.subCategory,
    subCategories,
  });

  return {
    id,
    chartProfileId: chartData.chartProfileId,
    label: chartData.label || defaultData.label,
    notes: chartData.notes || defaultData.notes,
    value: chartData.value || defaultData.value,
    category,
    subCategory,
    dateStart: chartData.timestampStart || defaultData.dateStart,
    dateEnd: chartData.timestampEnd || defaultData.dateEnd,
    dateCurrent: chartData.current,
    helped: chartData.helped || defaultHelped,
    displayStartMonth: chartData.displayStartMonth,
    displayStartDay: chartData.displayStartDay,
    displayStartYear: chartData.displayStartYear,
    displayEndMonth: chartData.displayEndMonth,
    displayEndDay: chartData.displayEndDay,
    displayEndYear: chartData.displayEndYear,
  };
};

const OverflowOption = styled("div")({
  fontSize: 16,
  lineHeight: "21px",
  display: "flex",
  gap: 12,
  alignItems: "center",
});

const dateBreakpoint = 600;
const iconBreakpoint = 500;

const DateWrapper = styled.div({
  display: "grid",
  gridTemplateColumns: "36% 1fr 36%",
  gap: 10,
  alignItems: "center",
  [`@media (max-width: ${dateBreakpoint}px)`]: {
    gridTemplateColumns: "60% 1fr",
  },
});

const ReadonlyText = styled.div({
  fontFamily: "Graphik Regular",
  fontWeight: 400,
  fontSize: 18,
  color: colors.dark85,
  lineHeight: "24px",
  whiteSpace: 'break-spaces',
});

const ToWrap = styled.div({
  display: "flex",
  flex: "100% 1",
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 8,
});

const To = styled.p({
  color: colors.dark75,
  margin: 0,
});

const Slash = styled.p({
  color: colors.light110,
  fontSize: 32,
  margin: 0,
  [`@media (max-width: ${dateBreakpoint}px)`]: {
    display: "none",
  },
});

const Present = styled("label")({
  fontFamily: "Graphik Regular",
  fontWeight: 400,
  color: colors?.dark85,
  cursor: 'pointer',
});

const getCategoryFromOption = (option: string | null) => chartCategoryConfig.find(
  (config) => config.category === option
) || chartCategoryConfig[0];

enum ValidatedFields {
  eventName,
  dateStart,
  dateEnd,
}

const MoreActionsButton = styled(NeutralButton)({
  display: 'flex',
  alignItems: 'center',
});

enum UIState {
  "default",
  "editSymptoms",
}

export const DataModal = ({
  modalState,
  chartData,
}: {
  modalState: ModalStateReturn;
  chartData?: OptionalChartDataData;
}) => {
  const [uiState, setUiState] = useState(UIState.default);
  const { subCategories } = useDisplayData();
  const isEditing = !!chartData && !!chartData.id;
  const chartDataAsData = useMemo(
    () => getChartData({ chartData, subCategories }),
    [chartData, subCategories]
  );
  const [data, setData] = useState(chartDataAsData);
  const [hasTriedSubmitting, setHasTriedSubmitting] = useState(false);
  const [addChartDataMutation] = useAddChartDataMutation();
  const [editChartDataMutation] = useEditChartDataMutation();
  const [removeChartDataMutation] = useRemoveChartDataMutation();
  const [invalidFields, setInvalidFields] = useState<ValidatedFields[]>([]);
  const helpedRadioState = useRadioState({ state: data.helped });
  const overflowMenuState = useMenuState();
  const myChartDataAccessContext = React.useContext(MyChartDataAccessContext);
  const isMobile = useMedia(`(max-width: ${iconBreakpoint}px)`);
  const canEditChartProfileData = canEditChartProfile(myChartDataAccessContext);

  // Seed our initial data
  useEffect(() => setData(chartDataAsData), [chartDataAsData]);

  // Basic validation
  const getInvalidFields = useCallback(() => {
    const newInvalidFields: ValidatedFields[] = [];

    if (!data.dateStart || !checkIfValidDate(data.dateStart)) {
      newInvalidFields.push(ValidatedFields.dateStart);
    }

    if (data.dateEnd && !checkIfValidDate(data.dateEnd)) {
      newInvalidFields.push(ValidatedFields.dateEnd);
    }

    if (
      data.dateEnd &&
      data.dateStart &&
      isBefore(data.dateEnd, data.dateStart)
    ) {
      newInvalidFields.push(ValidatedFields.dateEnd);
    }

    // Don't allow just a day and a year
    if (
      data.displayStartDay &&
      data.displayStartYear &&
      !data.displayStartMonth
    ) {
      newInvalidFields.push(ValidatedFields.dateStart);
    }

    // Don't allow just a day and a year
    if (data.displayEndDay && data.displayEndYear && !data.displayEndMonth) {
      newInvalidFields.push(ValidatedFields.dateEnd);
    }

    if (!data.label) {
      newInvalidFields.push(ValidatedFields.eventName);
    }

    return newInvalidFields;
  }, [
    data.label,
    data.dateStart,
    data.dateEnd,
    data.displayEndDay,
    data.displayEndMonth,
    data.displayEndYear,
    data.displayStartDay,
    data.displayStartMonth,
    data.displayStartYear,
  ]);

  useEffect(() => {
    if (hasTriedSubmitting) {
      const invalidFields = getInvalidFields();
      setInvalidFields(invalidFields);
    }
  }, [getInvalidFields, hasTriedSubmitting]);

  const clearForm = () => {
    setData(chartDataAsData);
  };

  const setSelectedSubCategory = useCallback((option: string) => {
    const subCategory = subCategories?.find((sc) => sc.id === option);

    if (subCategory) {
      setData((prevData) => ({
        ...prevData,
        subCategory,
      }));
    }
  }, [subCategories]);

  const setSelectedCategory = useCallback((option: string) => {
    const category = chartCategoryConfig.find(
      (config) => config.category === option
    );

    if (category) {
      setData((prevData) => ({
        ...prevData,
        category,
      }));

      // Diagnoses should get a default helped value of Yes
      if (category.category === ChartCategory.Diagnoses) {
        helpedRadioState.setState(DidItHelp.Yes);
      } else if (category.category === ChartCategory.TreatmentsSelfCare) {
        // Treatments should get a default helped value of Not sure
        helpedRadioState.setState(DidItHelp.NotSure);
      }
      const subCategory = getDefaultSubCategory({
        category,
        existingSubCategory: data.subCategory,
        subCategories,
      });

      if (subCategory) {
        setSelectedSubCategory(subCategory.id);
      } else {
        setData((prevData) => ({
          ...prevData,
          // Pass null, not undefined, to explicitly override if there was previously a subCategory
          subCategory: null,
        }));
      }
    }
  }, [data, helpedRadioState, setSelectedSubCategory, subCategories]);

  const categoryState = useSelectState({
    selected: data.category.category,
    setSelected: setSelectedCategory,
    initialPopoverState: {
      placement: "bottom-start",
    },
  });

  const subCategoryState = useSelectState({
    selected: data.subCategory?.id ? data.subCategory?.id.toString() : null,
    setSelected: setSelectedSubCategory,
    initialPopoverState: {
      placement: "bottom-start",
    },
  });

  const handleLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      label: e.target.value,
    });
  };
  const handleNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setData({
      ...data,
      notes: e.target.value,
    });
  };

  const handleAddSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!myChartDataAccessContext.currentlyViewingChartProfile) {
      return;
    }
    const invalidFields = getInvalidFields();
    const isDataValid = !invalidFields.length;

    analytics.track({
      event: EVENTS.CLICK_DATA_MODAL_ADD_BTN,
      properties: {
        isDataValid,
        chartCategory: data.category.category,
      },
    });

    if (!isDataValid) {
      setHasTriedSubmitting(true);
      setInvalidFields(invalidFields);
      return;
    }

    const timestampStart = data.dateStart;
    const timestampEnd = data.dateEnd || null;
    const inputData: ChartDataInputAdd = {
      chartProfileId: parseInt(myChartDataAccessContext.currentlyViewingChartProfile.id),
      chartCategory: data.category.category,
      subCategoryId: data.subCategory && parseInt(data.subCategory?.id),
      label: data.label,
      notes: data.notes,
      value: data.value,
      helped: helpedRadioState.state as DidItHelp,
      timestampStart,
      displayStartDay: data.displayStartDay,
      displayStartMonth: data.displayStartMonth,
      displayStartYear: data.displayStartYear,
      // Don't save end if current
      timestampEnd: !data.dateCurrent ? timestampEnd : null,
      displayEndDay: !data.dateCurrent ? data.displayEndDay : null,
      displayEndMonth: !data.dateCurrent ? data.displayEndMonth : null,
      displayEndYear: !data.dateCurrent ? data.displayEndYear : null,
      current: !!data.dateCurrent,
      always: false,
    };

    addChartDataMutation({
      variables: {
        data: inputData,
      },
      update(cache, { data }) {
        const addChartData = data?.addChartData;

        cache.modify({
          fields: {
            getChartDataForChartProfile(existingChartData = []) {
              const newDataRef = cache.writeFragment({
                data: addChartData,
                fragment: gql`
                  fragment NewChartData on ChartData {
                    id
                    chartCategory
                    label
                    value
                    notes
                    helped
                    subCategory {
                      id
                      name
                      color
                    }
                    chartProfileId
                    timestampStart
                    timestampEnd
                    displayEndDay
                    displayEndMonth
                    displayEndYear
                    displayStartDay
                    displayStartMonth
                    displayStartYear
                    current
                    always
                  }
                `,
              });
              return [...existingChartData, newDataRef];
            },
          },
        });
      },
    });

    clearForm();
    modalState.hide();
  };

  const handleEditSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const invalidFields = getInvalidFields();
    const isDataValid = !invalidFields.length;

    analytics.track({
      event: EVENTS.CLICK_DATA_MODAL_SAVE_BTN,
      properties: {
        isDataValid,
        chartCategory: data.category.category,
        dataTitleLength: data.label?.length,
        dataNoteLength: data.notes?.length,
        dataHelped: helpedRadioState.state,
        dataValue: data.value,
      },
    });
    if (!isDataValid) {
      setHasTriedSubmitting(true);
      setInvalidFields(invalidFields);
      return;
    }

    if (!chartData || !data.id || !data.chartProfileId) {
      return;
    }

    const timestampStart = data.dateStart;
    const timestampEnd = data.dateEnd || null;

    const inputData: ChartDataInputEdit = {
      id: parseInt(data.id),
      chartProfileId: data.chartProfileId,
      chartCategory: data.category.category,
      subCategoryId: data.subCategory && parseInt(data.subCategory?.id),
      label: data.label,
      notes: data.notes,
      value: data.value,
      helped: helpedRadioState.state as DidItHelp,
      timestampStart,
      displayStartDay: data.displayStartDay,
      displayStartMonth: data.displayStartMonth,
      displayStartYear: data.displayStartYear,
      timestampEnd: !data.dateCurrent ? timestampEnd : null,
      displayEndDay: !data.dateCurrent ? data.displayEndDay : null,
      displayEndMonth: !data.dateCurrent ? data.displayEndMonth : null,
      displayEndYear: !data.dateCurrent ? data.displayEndYear : null,
      current: !!data.dateCurrent,
      always: false,
    };
    editChartDataMutation({
      variables: {
        data: inputData,
      },
    });

    clearForm();
    modalState.hide();
  };

  const handleRemove = () => {
    if (!myChartDataAccessContext.currentlyViewingChartProfile || !data.id) {
      return;
    }
    const inputData: ChartDataInputDelete = {
      id: parseInt(data.id),
      chartProfileId: parseInt(myChartDataAccessContext.currentlyViewingChartProfile.id),
    };
    removeChartDataMutation({
      variables: {
        data: inputData,
      },
      update(cache) {
        const normalizedId = cache.identify({
          id: data.id,
          __typename: "ChartData",
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
    });
    setData({ ...defaultData });
    modalState.hide();
  };

  const handleDateStartChange = (newValues: DateInputValues) => {
    if (
      newValues.month.length === 0 &&
      newValues.day.length === 0 &&
      newValues.year.length === 0
    ) {
      setData({
        ...data,
        dateStart: null,
        displayStartMonth: undefined,
        displayStartDay: undefined,
        displayStartYear: undefined,
      });

      return;
    }

    const estimatedValues = getEstimatedValues(newValues);
    const constructedDateString =
      generateEstimatedDateFromValues(estimatedValues);
    // Handles tricky validation like 10/32/2023
    const parsedDate = parse(constructedDateString, "P", new Date(), {
      locale: enUS,
    });

    const displayStartMonth = newValues.month.length ? parseInt(newValues.month, 10) : undefined;
    const displayStartDay = newValues.day.length ? parseInt(newValues.day, 10) : undefined;
    const displayStartYear = newValues.year.length ? parseInt(newValues.year, 10) : undefined;

    setData({
      ...data,
      dateStart: parsedDate,
      displayStartMonth,
      displayStartDay,
      displayStartYear,
    });
  };

  const handleDateEndChange = (newValues: DateInputValues) => {
    if (
      newValues.month.length === 0 &&
      newValues.day.length === 0 &&
      newValues.year.length === 0
    ) {
      setData({
        ...data,
        dateEnd: undefined,
        displayEndMonth: undefined,
        displayEndDay: undefined,
        displayEndYear: undefined,
      });

      return;
    }

    const estimatedValues = getEstimatedValues(newValues);
    const constructedDateString =
      generateEstimatedDateFromValues(estimatedValues);
    // Handles tricky validation like 10/32/2023
    const parsedDate = parse(constructedDateString, "P", new Date(), {
      locale: enUS,
    });

    const displayEndMonth = newValues.month.length
      ? parseInt(newValues.month, 10)
      : undefined;
    const displayEndDay = newValues.day.length
      ? parseInt(newValues.day, 10)
      : undefined;
    const displayEndYear = newValues.year.length
      ? parseInt(newValues.year, 10)
      : undefined;

    setData({
      ...data,
      dateEnd: parsedDate,
      displayEndMonth,
      displayEndDay,
      displayEndYear,
    });
  };

  const nameRef: React.RefObject<HTMLInputElement> | null = useRef(null);

  // Focus eventName field when first opening add dialog
  useEffect(() => {
    if (nameRef.current && !isEditing) {
      nameRef.current.focus();
    }
  }, [isEditing]);

  const displayStartValues = useMemo(
    () => ({
      month: typeof data.displayStartMonth === 'number' ? data.displayStartMonth.toString() : "",
      day: typeof data.displayStartDay === 'number' ? data.displayStartDay.toString() : "",
      year: typeof data.displayStartYear === 'number' ? data.displayStartYear.toString() : "",
    }),
    [data.displayStartMonth, data.displayStartDay, data.displayStartYear]
  );

  const displayEndValues = useMemo(
    () => ({
      month: typeof data.displayEndMonth === 'number' ? data.displayEndMonth.toString() : "",
      day: typeof data.displayEndDay === 'number' ? data.displayEndDay.toString() : "",
      year: typeof data.displayEndYear === 'number' ? data.displayEndYear.toString() : "",
    }),
    [data.displayEndMonth, data.displayEndDay, data.displayEndYear]
  );

  const handleKeyPress = (e: React.KeyboardEvent<HTMLFormElement>) => {
    const element = e.target as HTMLElement;
    if (e.key === "Enter" && element.tagName.toLowerCase() !== 'textarea') {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
  }

  const overflowMenuItems: React.ComponentPropsWithoutRef<'button'>[] = [];

  if (chartData?.id) {
    overflowMenuItems.push({
      children: (
        <OverflowOption>
          <Icon name="delete" /> Remove
        </OverflowOption>
      ),
      onClick: handleRemove,
    });
  }

  const selectedCategory = getCategoryFromOption(categoryState.selected);
  const selectedSubCategory = useMemo(
    () =>
      subCategories
        ? subCategories.find((sc) => sc.id === subCategoryState.selected) ||
          subCategories[0]
        : null,
    [subCategories, subCategoryState.selected]
  );

  const editSymptoms: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    setUiState(UIState.editSymptoms);
  };

  // Don't let users close out of Edit Symptoms because
  // we can't guarantee everything is in a valid state to save
  const isDismissible = uiState === UIState.default;

  const handleDoneEditingSymptoms = () => setUiState(UIState.default);

  const getInner = () => {
    switch (uiState) {
      case UIState.default:
        return (
          <form
            onSubmit={isEditing ? handleEditSubmit : handleAddSubmit}
            onKeyPress={handleKeyPress}
          >
            <ModalHeader {...modalState}>
              <HeaderWrap>
                <SelectTrigger {...categoryState} disabled={!canEditChartProfileData}>
                  <CategorySelectedOption
                    option={selectedCategory}
                    isSelectable={canEditChartProfileData}
                  />
                </SelectTrigger>
                <Select {...categoryState}>
                  {chartCategoryConfig.map((category) => (
                    <SelectOption
                      value={category.category}
                      {...categoryState}
                      key={category.category}
                    >
                      <CategorySelectedOption option={category} />
                    </SelectOption>
                  ))}
                </Select>
                {selectedCategory.canUseSubCategories &&
                  subCategories &&
                  selectedSubCategory && (
                    <>
                      <SelectTrigger
                        {...subCategoryState}
                        disabled={!canEditChartProfileData}
                      >
                        <SubCategorySelectedOption
                          option={selectedSubCategory}
                          isSelectable={canEditChartProfileData}
                        />
                      </SelectTrigger>
                      <Select {...subCategoryState}>
                        {subCategories?.map((subCategory) => (
                          <SelectOption
                            value={subCategory.id}
                            {...subCategoryState}
                            key={subCategory.id}
                          >
                            <SubCategorySelectedOption option={subCategory} />
                          </SelectOption>
                        ))}
                        <StyledSelectOption
                          key="edit"
                          onClick={editSymptoms}
                          style={{ paddingLeft: 42 }}
                        >
                          Edit symptoms
                        </StyledSelectOption>
                      </Select>
                    </>
                  )}
              </HeaderWrap>
            </ModalHeader>
            <ModalBody {...modalState}>
              <Field>
                <Input
                  id="eventName"
                  name="eventName"
                  placeholder={`Enter ${selectedCategory.text.singular}*`}
                  type="text"
                  value={data.label}
                  onChange={handleLabelChange}
                  size={InputSize.large}
                  ref={nameRef}
                  $isInvalid={invalidFields.includes(ValidatedFields.eventName)}
                  tabIndex={0}
                  readOnly={!canEditChartProfileData}
                />
                {invalidFields.includes(ValidatedFields.eventName) && (
                  <FieldError>Name is required</FieldError>
                )}
              </Field>
              <Field withLabel={true}>
                {!isMobile && (
                  <Label>
                    <Icon name="calendar_today" />
                  </Label>
                )}
                {canEditChartProfileData ? (
                  <DateWrapper>
                    <DateInputs
                      displayValues={displayStartValues}
                      dateInputRequired={{
                        year: true,
                      }}
                      onChange={handleDateStartChange}
                      error={
                        invalidFields.includes(ValidatedFields.dateStart)
                          ? "Invalid date"
                          : undefined
                      }
                    />
                    {data.category.canUseSpan && (
                      <>
                        <ToWrap>
                          <To>to</To>
                          <Present>
                            <Checkbox
                              onChange={() =>
                                setData({
                                  ...data,
                                  dateCurrent: !data.dateCurrent,
                                })
                              }
                              checked={!!data.dateCurrent}
                              tabIndex={0}
                            />
                            Present
                          </Present>
                          <Slash>/</Slash>
                        </ToWrap>
                        <DateInputs
                          displayValues={displayEndValues}
                          dateInputRequired={{
                            year: true,
                          }}
                          onChange={handleDateEndChange}
                          error={
                            invalidFields.includes(ValidatedFields.dateEnd)
                              ? "Invalid date"
                              : undefined
                          }
                          disabled={!!data.dateCurrent}
                        />
                      </>
                    )}
                  </DateWrapper>
                ) : chartData ? (
                  <ReadonlyText>
                    {getDatesDisplay(chartData)}
                  </ReadonlyText>
                ) : null}
              </Field>
              {(canEditChartProfileData || !!data.notes?.length) && (
                <Field withLabel={true}>
                  {!isMobile && (
                    <Label>
                      <Icon name="text_snippet" />
                    </Label>
                  )}
                  {canEditChartProfileData ?
                    <Textarea
                      placeholder="Notes (optional)"
                      value={data.notes}
                      onChange={handleNotesChange}
                      tabIndex={0}
                    />
                    : <ReadonlyText>{data.notes}</ReadonlyText>
                  }
                </Field>
              )}
              <DataModalSliderInput
                value={data.value}
                category={data.category}
                subCategory={data.subCategory}
                onChange={(newValue) => setData({ ...data, value: newValue })}
                readOnly={!canEditChartProfileData}
              />
              <ThreeButtonsField
                category={data.category}
                readOnly={!canEditChartProfileData}
                {...helpedRadioState}
              />
            </ModalBody>
            {canEditChartProfileData && (
              <ModalFooter>
                <Button
                  color={colors.burgundy}
                  type="submit"
                  disabled={invalidFields.length > 0}
                  tabIndex={0}
                >
                  {isEditing ? "Save" : "Add"}
                </Button>
                {!!overflowMenuItems.length && (
                  <>
                    <MenuButton
                      {...overflowMenuState}
                      as={MoreActionsButton}
                      color={colors.buttonColor}
                    >
                      More actions <Icon name="keyboard_arrow_down" />
                    </MenuButton>
                    <DropdownMenu aria-label="More" {...overflowMenuState}>
                      {overflowMenuItems.map((item, i) => (
                        <DropdownMenuItem
                          {...overflowMenuState}
                          {...item}
                          key={i}
                        />
                      ))}
                    </DropdownMenu>
                  </>
                )}
              </ModalFooter>
            )}
          </form>
        );
      case UIState.editSymptoms:
        return (
          <EditSymptoms onKeyPress={handleKeyPress} modalState={modalState} subCategories={subCategories} onDone={handleDoneEditingSymptoms} />
        );
      default:
        missingCaseError(uiState);
    }
  }

  return (
    <Modal {...modalState} isDismissible={isDismissible}>
      {getInner()}
    </Modal>
  );
};
