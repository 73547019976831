import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
  CookieStorage,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import { format } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import createPersistedState from "use-persisted-state";

import { cognito } from "../config";
import {
  AddUserDocument,
  useWhoAmILazyQuery,
  UserInput,
} from "../data/graphql/generated/graphql";
import { navigate } from "@reach/router";
import { routes } from "../App";
import { useApolloClient } from "@apollo/client";
import { analytics } from "../analytics";

interface AuthAttempt {
  success: boolean;
  error?: any;
}

type LoginParams = { username: string; password: string };
type Login = (args: LoginParams) => Promise<AuthAttempt>;

type SignupParams = {
  name: string;
  email: string;
  password: string;
  signup_code: string;
  dateOfBirth: Date | null;
};
type Signup = (args: SignupParams) => Promise<AuthAttempt>;

type ConfirmParams = {
  confirmCode: string;
};
type Confirm = (args: ConfirmParams) => Promise<AuthAttempt>;

type ResetPasswordParams = { username: string; };
type ResetPassword = (args: ResetPasswordParams) => Promise<AuthAttempt>;

type NewPasswordParams = { username: string; password: string; verificationCode: string };
type NewPassword = (args: NewPasswordParams) => Promise<AuthAttempt>;

type CurrentUser = {
  id: string;
  displayName: string;
  isSuperAdmin: boolean;
};

interface AuthContextInterface {
  currentUser: CurrentUser | null;
  login: Login;
  logout: (callback: () => void) => void;
  signup: Signup;
  confirm: Confirm;
  resendConfirm: () => Promise<AuthAttempt>;
  resetPassword: ResetPassword;
  newPassword: NewPassword;
  asSuperAdmin: boolean;
  setAsSuperAdmin: (asSuperAdmin: boolean) => void;
  userLoading: boolean;
  isUnconfirmed?: boolean;
}

const AuthContext = React.createContext<AuthContextInterface | null>(null);

const domain = process.env.REACT_APP_DOMAIN;
const userPool = new CognitoUserPool({
  ...cognito,
  Storage: new CookieStorage({
    domain,
  }),
});
const existingCognitoUser = userPool.getCurrentUser();

export const isCognitoErrorFromUnconfirmedUser = (error: any) =>
  error && error.code === "UserNotConfirmedException";
const useAsSuperAdminState = createPersistedState<boolean>(
  "asSuperAdmin"
);

let accessToken: string | null;
export const getAccessToken = () => accessToken;

// Access tokens expire every 60 minutes, so refresh them every 55
const refreshTokenIntervalInMinutes = 55;
const refreshTokenIntervalInMs = refreshTokenIntervalInMinutes * 60 * 1000;

export const expireAuth = () => {
  analytics.reset();
  navigate(routes.logout, { state: { expired: true } });
};

const useProvideAuth = () => {
  const [tempCreds, setTempCreds] = useState<{
    email: string;
    password: string;
  } | null>(null);
  const [currentUser, setCurrentUser] =
    useState<CurrentUser | null>(null);
  const [currentCognitoUser, setCurrentCognitoUser] =
    useState<CognitoUser | null>(existingCognitoUser);
  const [isUnconfirmed, setIsUnconfirmed] =
    useState<boolean>(existingCognitoUser ? false : true);
  const [asSuperAdmin, setAsSuperAdmin] = useAsSuperAdminState(false);

  const client = useApolloClient();

  const [getWhoAmIQuery, { data: whoAmIData, error: whoAmIError }] = useWhoAmILazyQuery({
    client,
  });
  const refreshTokenIntervalId = useRef<ReturnType<typeof setInterval>>();

  useEffect(() => {
    if (currentCognitoUser && !isUnconfirmed) {
      currentCognitoUser.getSession(
        (error: Error | null, session: CognitoUserSession) => {
          if (!error && session.isValid()) {
            accessToken = session.getAccessToken().getJwtToken();

            getWhoAmIQuery();

            const refreshToken = session.getRefreshToken();
            refreshTokenIntervalId.current = setInterval(() => {
              currentCognitoUser.refreshSession(
                refreshToken,
                (error: Error | null, session: CognitoUserSession) => {
                  if (!error && session.isValid()) {
                    accessToken = session.getAccessToken().getJwtToken();
                  } else {
                    expireAuth();
                  }
                }
              );
            }, refreshTokenIntervalInMs);
            return;
          }
          expireAuth();
        }
      );
    }
  }, [currentCognitoUser, getWhoAmIQuery, isUnconfirmed]);

  useEffect(() => {
    if (whoAmIData?.whoAmI) {
      setCurrentUser({
        id: whoAmIData.whoAmI.id,
        displayName: whoAmIData.whoAmI.displayName,
        isSuperAdmin: !!whoAmIData.whoAmI.isSuperAdmin,
      });
      analytics.identify(whoAmIData.whoAmI.analyticsId);
      analytics.register({
        userIsInternal: !!whoAmIData.whoAmI.isSuperAdmin,
        userSignupCode: whoAmIData.whoAmI.signupCode,
        userCreatedAt: whoAmIData.whoAmI.createdAt,
      });
    } else {
      setCurrentUser(null);
      analytics.reset();
    }
  }, [whoAmIData]);

  // Make sure we clean up our interval so it doesn't try later
  useEffect(() => {
    return () => {
      if (refreshTokenIntervalId.current) {
        clearInterval(refreshTokenIntervalId.current);
      }
    };
  }, []);

  const userLoading = useMemo(
    () => !!currentCognitoUser && !currentUser && !isUnconfirmed,
    [currentCognitoUser, currentUser, isUnconfirmed]
  );

  const login = useMemo(
    () =>
      ({ username, password }: LoginParams) => {
        return new Promise<AuthAttempt>((resolve, reject) => {
          const authenticationData = {
            Username: username,
            Password: password,
          };
          const authenticationDetails = new AuthenticationDetails(
            authenticationData
          );

          const userData = {
            Username: username,
            Pool: userPool,
            Storage: new CookieStorage({
              domain,
            }),
          };

          const user = new CognitoUser(userData);
          user.authenticateUser(authenticationDetails, {
            onSuccess: function (result) {
              accessToken = result.getAccessToken().getJwtToken();
              setIsUnconfirmed(false);
              setCurrentCognitoUser(user);
              resolve({
                success: true,
              });
            },
            onFailure: function (error) {
              if (isCognitoErrorFromUnconfirmedUser(error)) {
                // Store the user, but then reject so that we can redirect to the confirm page
                setIsUnconfirmed(true);
                setCurrentCognitoUser(user);
              }

              reject({
                success: false,
                error,
              });
            },
          });
        });
      },
    []
  );

  const logout = useMemo(
    () => (callback: () => void) => {
      if (currentCognitoUser) {
        if (refreshTokenIntervalId.current) {
          clearInterval(refreshTokenIntervalId.current);
        }
        // Wrap in try because cognito seems to want to break
        try {
          currentCognitoUser?.signOut(() => {
            setCurrentCognitoUser(null);
            setCurrentUser(null);
            accessToken = null;
            callback();
          });
        } catch (e) {
          callback();
        }
      } else {
        callback();
      }

      analytics.reset();
    },
    [currentCognitoUser]
  );

  const signup = useMemo(
    () =>
      ({ name, email, password, signup_code, dateOfBirth }: SignupParams) => {
        return new Promise<AuthAttempt>((resolve, reject) => {
          const trimmedName = name.trim();
          const trimmedEmail = email.trim();
          const trimmedPassword = password.trim();
          const trimmedCode = signup_code.trim();
          const localTimezone =
            Intl.DateTimeFormat().resolvedOptions().timeZone;
          const dateOfBirthOrFallback = dateOfBirth || new Date("1923-01-01");
          const birthdate = format(dateOfBirthOrFallback, "yyyy-MM-dd");
          const localDob = zonedTimeToUtc(dateOfBirthOrFallback, localTimezone);
          const localBirthdate = localDob.toISOString();
          const attributeList = [
            new CognitoUserAttribute({
              Name: "name",
              Value: trimmedName,
            }),
            new CognitoUserAttribute({
              Name: "email",
              Value: trimmedEmail,
            }),
            // Cognito takes a 10 digit birthday in format "yyyy-MM-dd"
            new CognitoUserAttribute({
              Name: "birthdate",
              Value: birthdate,
            }),
            new CognitoUserAttribute({
              Name: "custom:signup_code",
              Value: trimmedCode,
            }),
          ];
          const validationData = [
            new CognitoUserAttribute({
              Name: "signup_code",
              Value: trimmedCode,
            }),
          ];
          userPool.signUp(
            trimmedEmail,
            trimmedPassword,
            attributeList,
            validationData,
            (error, result) => {
              if (error || !result?.user) {
                reject({
                  success: false,
                  error,
                });
                return;
              }
              setCurrentCognitoUser(result?.user);
              setIsUnconfirmed(true);
              setTempCreds({
                email: trimmedEmail,
                password: trimmedPassword,
              });
              resolve({
                success: true,
              });
            },
            // ClientMetadata that we can pass to the lambda
            {
              // We need to translate birthday to include the local timezone
              localBirthdate,
            }
          );
        });
      },
    []
  );

  const addUser = useCallback(() => {
    return new Promise<AuthAttempt>((resolve, reject) => {
      if (currentCognitoUser) {
        currentCognitoUser.getUserAttributes(
          (userAttributesError, userAttributes) => {
            if (userAttributesError) {
              reject({
                success: false,
                error: userAttributesError,
              });
            } else if (userAttributes) {
              let displayName: string | undefined;
              let email: string | undefined;
              let signupCode: string | undefined;
              let dateOfBirth: string | undefined;
              userAttributes.forEach((attribute) => {
                const attributeName = attribute.getName();
                if (attributeName === "name") {
                  displayName = attribute.getValue();
                } else if (attributeName === "email") {
                  email = attribute.getValue();
                } else if (attributeName === "custom:signup_code") {
                  signupCode = attribute.getValue();
                }
                if (attributeName === "birthdate") {
                  const birthdate = attribute.getValue();
                  // We only store the date in cognito, we need to translate it to
                  // include the local timezone
                  const localTimezone =
                    Intl.DateTimeFormat().resolvedOptions().timeZone;
                  const localDob = zonedTimeToUtc(birthdate, localTimezone);
                  dateOfBirth = localDob.toISOString();
                }
              });

              if (!displayName || !signupCode || !dateOfBirth || !email) {
                reject({
                  success: false,
                });
                return;
              }
              const userInput: UserInput = {
                username: currentCognitoUser?.getUsername(),
                displayName,
                signupCode,
                dateOfBirth,
                email,
              };
              client
                .mutate({
                  mutation: AddUserDocument,
                  variables: {
                    user: userInput,
                  },
                })
                .then(() => {
                  resolve({
                    success: true,
                  });
                })
                .catch((error) => {
                  reject({
                    success: false,
                    error,
                  });
                });
            } else {
              reject({
                success: false,
              });
            }
          }
        );
      } else {
        reject({
          success: false,
        });
      }
    });
  }, [client, currentCognitoUser]);

  const confirm = useMemo(
    () =>
      ({ confirmCode }: ConfirmParams) => {
        return new Promise<AuthAttempt>((resolve, reject) => {
          const trimmedConfirmCode = confirmCode.trim();
          if (currentCognitoUser) {
            currentCognitoUser.confirmRegistration(
              trimmedConfirmCode,
              true,
              (error) => {
                if (error) {
                  reject({
                    success: false,
                    error,
                  });
                  return;
                }
                if (tempCreds) {
                  const authenticationData = {
                    Username: tempCreds?.email,
                    Password: tempCreds?.password,
                  };
                  const authenticationDetails =
                    new AuthenticationDetails(authenticationData);
                  currentCognitoUser?.authenticateUser(
                    authenticationDetails,
                    {
                      onSuccess: function (result) {
                        accessToken = result
                          .getAccessToken()
                          .getJwtToken();
                        addUser().then(() => {
                          setIsUnconfirmed(false);
                          resolve({
                            success: true,
                          });
                        }).catch((e) => reject({
                          success: false,
                          error,
                        }))
                      },
                      onFailure: function (error) {
                        reject({
                          success: false,
                          error,
                        });
                      },
                    }
                  );
                } else {
                  setIsUnconfirmed(false);
                  reject({
                    success: false,
                    error: 'NEEDS_TO_FINISH_LOGIN'
                  });
                }
              }
            );
          } else {
            reject({
              success: false,
            });
          }
        });
      },
    [currentCognitoUser, tempCreds, addUser]
  );

  const resendConfirm = useMemo(
    () => () => {
      return new Promise<AuthAttempt>((resolve, reject) => {
        if (currentCognitoUser) {
          currentCognitoUser?.resendConfirmationCode(function (error, result) {
            if (error) {
              reject({
                success: false,
                error,
              });
            }
            resolve({
              success: true,
            });
          });
        } else {
          reject({
            success: false,
            error: 'NEEDS_TO_LOGIN_FIRST',
          });
        }
      });
    },
    [currentCognitoUser]
  );

  const resetPassword = useMemo(
    () =>
      ({ username }: ResetPasswordParams) => {
        return new Promise<AuthAttempt>((resolve, reject) => {
          const cognitoUser = new CognitoUser({
            Username: username,
            Pool: userPool,
          });

          cognitoUser.forgotPassword({
            onSuccess: function (result) {
              // console.log("call result: " + result);
            },
            onFailure: function (error) {
              reject({
                success: false,
                error,
              });
            },
            inputVerificationCode() {
              resolve({
                success: true,
              });
            },
          });
        });
      },
    []
  );

  const newPassword = useMemo(
    () =>
      ({ username, password, verificationCode }: NewPasswordParams) => {
        return new Promise<AuthAttempt>((resolve, reject) => {
          const cognitoUser = new CognitoUser({
            Username: username,
            Pool: userPool,
          });

          cognitoUser.confirmPassword(verificationCode, password, {
            onFailure(error) {
              reject({
                success: false,
                error,
              });
            },
            onSuccess() {
              resolve({
                success: true,
              });
            },
          });
        });
      },
    []
  );

  useEffect(() => {
    // Handle the edge case where a user was created in Cognito but not our DB
    if (whoAmIError && currentCognitoUser) {
      // @ts-ignore-next-line
      if (whoAmIError.networkError?.statusCode === 404) {
        addUser().then(() => getWhoAmIQuery());
      }
    }
  }, [whoAmIError, addUser, getWhoAmIQuery, currentCognitoUser]);

  return useMemo(
    () => ({
      currentUser,
      login,
      logout,
      signup,
      confirm,
      resendConfirm,
      resetPassword,
      newPassword,
      asSuperAdmin,
      setAsSuperAdmin,
      userLoading,
    }),
    [
      currentUser,
      login,
      logout,
      signup,
      confirm,
      resendConfirm,
      resetPassword,
      newPassword,
      asSuperAdmin,
      setAsSuperAdmin,
      userLoading,
    ]
  );
}

export const AuthProvider = ({ children }: { children: React.ReactElement}) => {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
  return React.useContext(AuthContext);
}
