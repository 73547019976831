import React, { useContext, useMemo } from "react";
import {
  DisclosureContent,
  Disclosure,
  DisclosureStateReturn,
  useCompositeState,
  CompositeItem,
  Composite,
  Checkbox,
} from "reakit";
import styled from "styled-components";
import { navigate } from "@reach/router";

import { colors } from "../constants/theme";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
import { neutralButtonStyles } from "./base/NeutralButton";
import { useAuth } from "../context/AuthContext";
import { MyChartDataAccessContext } from "../context/MyChartDataAccessContext";
import { routes } from "../App";
import { uiConstants } from "../uiConstants";
import { AccessIcon } from "./base/AccessIcon";
import { Icon } from "./base/Icon";

export const navHorizontalPadding = 16;

const Flyout = styled(DisclosureContent)({
  position: "fixed",
  top: 0,
  left: 0,
  bottom: 0,
  width: 328,
  backgroundColor: colors.dark,
  zIndex: 3,
  display: 'flex',
  flexDirection: 'column',
});

const flyoutIconSize = 36;

export const FlyoutDisclosure = styled(Disclosure)({
  ...neutralButtonStyles,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: 0,
  width: flyoutIconSize,
  flexShrink: 0,
  color: colors.light90,
});

const FlyoutHeader = styled("div")({
  height: uiConstants.navbar.height,
  flexShrink: 0,
  display: "flex",
  alignItems: "center",
  gap: 10,
  color: colors.light90,
  borderBottom: `1px solid ${colors.dark50}`,
  margin: `0 ${navHorizontalPadding}px`,
});

const FlyoutName = styled("div")({
  fontSize: 20,
  fontFamily: "Graphik Medium",
  flexGrow: 1,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
});

const logoWidth = 46;
const SizedLogo = styled(Logo)({
  width: logoWidth,
});

const MenuItem = styled(CompositeItem)<{ $isActive?: boolean }>(({ $isActive }) => ({
  border: "none",
  outline: "none",
  cursor: "pointer",
  background: $isActive ? colors.dark85 : "transparent",
  textDecoration: "none",
  textAlign: "left",
  padding: `10px 0`,
  margin: `10px 0`,
  display: "block",
  boxSizing: "border-box",
  width: "100%",
  fontSize: 16,
  color: colors.light90,
  "&:active, &:hover, &:focus": {
    backgroundColor: colors.dark75,
  },
}));

const TimelineMenu = styled(Composite)({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflow: 'hidden',
});

const TimelineMenuHeading = styled("div")({
  display: "flex",
  padding: `10px ${navHorizontalPadding}px`,
  margin: `10px 0`,
  fontSize: 18,
  fontFamily: "Graphik Medium",
  color: colors.light90,
});

const TimelineIcon = styled('div')({
  width: logoWidth,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
});

const AvailableTimelines = styled("div")({
  overflow: "auto",
  [MenuItem]: {
    paddingTop: 10,
    paddingRight: navHorizontalPadding,
    paddingBottom: 10,
    paddingLeft: 16 + navHorizontalPadding,
    margin: '5px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const BottomMenu = styled(Composite)({
  flexShrink: 0,
  "&::before": {
    content: "''",
    borderTop: `1px solid ${colors.dark50}`,
    margin: `0 ${navHorizontalPadding}px`,
    display: 'block',
  },
  [MenuItem]: {
    paddingLeft: navHorizontalPadding,
    paddingRight: navHorizontalPadding,
    fontFamily: "Graphik Medium",
  },
});

export const FlyoutMenu = ({ flyoutState }: { flyoutState: DisclosureStateReturn }) => {
  const composite = useCompositeState();
  const auth = useAuth();
  const currentUser = auth?.currentUser;
  const myChartDataAccessContext = useContext(MyChartDataAccessContext);

  const handleLogout = () => {
    myChartDataAccessContext.setCurrentlyViewingChartProfileId(null);
    navigate(routes.logout);
  };

  const handleToggleAsSuperAdmin = () => {
    auth?.setAsSuperAdmin(!auth?.asSuperAdmin);
  };

  const timelineCount = myChartDataAccessContext.myChartDataAccess.length;
  const timelineCountText = timelineCount === 1 ? "" : ` (${timelineCount})`;
  const myId = currentUser?.id;

  const sortedTimelines = useMemo(() => {
    return [...myChartDataAccessContext.myChartDataAccess].sort((a, b) => {
      if (a.aboutChartProfile.id === myId) {
        return -1;
      }
      if (b.aboutChartProfile.id === myId) {
        return 1;
      }
      if (a.aboutChartProfile.displayName < b.aboutChartProfile.displayName) {
        return -1;
      }
      if (a.aboutChartProfile.displayName > b.aboutChartProfile.displayName) {
        return 1;
      }
      return 0;
    });
  }, [myChartDataAccessContext.myChartDataAccess, myId]);
  // Fix react error
  const { wrap, ...restOfComposite } = composite;
  return (
    <Flyout {...flyoutState}>
      <FlyoutHeader>
        <FlyoutDisclosure {...flyoutState}>
          <Icon
            name="close"
            style={{
              fontSize: 35,
            }}
          />
        </FlyoutDisclosure>
        <FlyoutName>Hi, {currentUser?.displayName}</FlyoutName>
        <SizedLogo />
      </FlyoutHeader>
      <TimelineMenu>
        <TimelineMenuHeading>
          Health stories{timelineCountText}
        </TimelineMenuHeading>
        <AvailableTimelines>
          {sortedTimelines.map((chartDataAccess) => (
            <MenuItem
              {...composite}
              onClick={() => {
                myChartDataAccessContext.setCurrentlyViewingChartProfileId(
                  chartDataAccess.aboutChartProfile.id
                );
                flyoutState.hide();
              }}
              key={chartDataAccess.aboutChartProfile.id}
              $isActive={
                chartDataAccess.aboutChartProfile.id ===
                myChartDataAccessContext.currentlyViewingChartProfile?.id
              }
            >
              {chartDataAccess.aboutChartProfile.displayName}
              {chartDataAccess.aboutChartProfile.id === myId ? " (me)" : ""}
              <TimelineIcon>
                <AccessIcon accessType={chartDataAccess.accessType} />
              </TimelineIcon>
            </MenuItem>
          ))}
        </AvailableTimelines>
      </TimelineMenu>
      <BottomMenu>
        {currentUser?.isSuperAdmin && (
          <MenuItem {...composite} onClick={handleToggleAsSuperAdmin}>
            <Checkbox onChange={() => {}} checked={auth?.asSuperAdmin} /> Use as
            super admin
          </MenuItem>
        )}
        {/* <MenuItem {...composite}>Account settings</MenuItem> */}
        <MenuItem
          {...restOfComposite}
          as="a"
          href="https://www.pictalhealth.com/help"
          target="_blank"
        >
          Help
        </MenuItem>
        <MenuItem {...composite} onClick={handleLogout}>
          Logout
        </MenuItem>
      </BottomMenu>
    </Flyout>
  );
};
