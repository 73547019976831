// old demo
// export const cognito = {
//   Region: "us-east-1",
//   UserPoolId: "us-east-1_8sWV5Udnd",
//   ClientId: "48baiurmtsd72eesq7dra4ma9q",
// };

export const cognito =
  process.env.REACT_APP_ENV === "production"
    ? {
        Region: "us-east-1",
        UserPoolId: "us-east-1_GQurhvU2M",
        ClientId: "29n48dlvb2so454psg5ia24bcn",
      }
    : {
        Region: "us-east-1",
        UserPoolId: "us-east-1_GfuZteMhx",
        ClientId: "7mat2jj47ivqh6gohrbgabn1m6",
      };
