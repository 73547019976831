import React, { useCallback, useContext } from "react";
import styled, { CSSObject } from "styled-components";
import { colors } from "../../constants/theme";
import { StyledFilterToggleButton, TOGGLE_BUTTON_HEIGHT } from './Filters/FilterToggleButton';
import { DownloadModeContext } from "../../context/DownloadModeContext";
import { MenuButton, Portal, useMenuState } from "reakit";
import { DropdownMenu, DropdownMenuItem } from "../base/DropdownMenu";
import { useDataModal } from "./DataModalContext";
import { ChartCategory } from "../../data/graphql/generated/graphql";
import { useDisplayData } from "./Timeline/DisplayDataContext";
import { analytics } from "../../analytics";
import { EVENTS } from "../../analytics/events";
import { MyChartDataAccessContext, canEditChartProfile } from "../../context/MyChartDataAccessContext";
import { Icon } from "../base/Icon";

const PositionedSection = styled("section")({
  position: "relative",
  padding: 0,
  margin: 0,
  minHeight: 50,
  width: "100%",
  borderBottom: `1px solid ${colors.light}`,
  ':last-child': {
    borderBottom: 'none',
  },
});

const BUTTON_HORIZONTAL_PADDING = 8;
const BUTTON_TOP = TOGGLE_BUTTON_HEIGHT + 20;
const BUTTON_MARGIN = 7;
const CategoryButton = styled(StyledFilterToggleButton)(
  ({ $shouldTopAlign }: { $shouldTopAlign?: boolean }) =>
    ({
      position: $shouldTopAlign ? "sticky" : "absolute",
      top: $shouldTopAlign ? BUTTON_TOP : "50%",
      marginTop: BUTTON_MARGIN,
      marginBottom: BUTTON_MARGIN,
      transform: $shouldTopAlign ? "none" : "translateY(-50%)",
      zIndex: 1,
    } as CSSObject)
);

const PositionedButtonWrapper = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  pointerEvents: 'none',
});

const CategoryPrintLabel = styled("span")({
  paddingLeft: BUTTON_HORIZONTAL_PADDING,
  paddingRight: BUTTON_HORIZONTAL_PADDING,
  position: "relative",
  top: 30,
});

export type CategoryProps = {
  text: {
    plural: string;
    singular: string;
    article: string;
  };
  children: React.ReactElement;
  shouldTopAlign?: boolean;
  chartCategory: ChartCategory
};

export const Category = ({
  text,
  children,
  shouldTopAlign,
  chartCategory,
}: CategoryProps) => {
  const { isDownloadMode } = useContext(DownloadModeContext);
  const menuState = useMenuState();
  const { setModalData, showModal } = useDataModal();
  const myChartDataAccessContext = React.useContext(MyChartDataAccessContext);
  const canEditChartProfileData = canEditChartProfile(myChartDataAccessContext);
  const addWithSelectedCategory = () => {
    setModalData({
      chartCategory,
    });
    showModal();

    analytics.track({
      event: EVENTS.CLICK_ADD_TO_TIMELINE,
      properties: {
        clickLocation: "categoryButton",
        chartCategory,
      },
    });
  };
  const { toggleCategory } = useDisplayData();

  const hideCategory = useCallback(
    () => toggleCategory(chartCategory),
    [toggleCategory, chartCategory]
  );

  const addText = text.singular[0].toLocaleLowerCase() + text.singular.slice(1);

  return (
    <PositionedSection>
      <PositionedButtonWrapper>
        {isDownloadMode ? (
          <CategoryPrintLabel className="category-label">
            {text.plural}
          </CategoryPrintLabel>
        ) : (
          <>
            <MenuButton
              {...menuState}
              as={CategoryButton}
              $shouldTopAlign={shouldTopAlign}
              $buttonColors={{
                color: colors.dark,
                backgroundColor: colors.white,
                borderColor: colors.illustrationGrey,
              }}
            >
              {text.plural}
            </MenuButton>
            <Portal>
              <DropdownMenu
                aria-label="More"
                {...menuState}
                style={{ zIndex: 3, pointerEvents: "all", maxWidth: '100%' }}
              >
                {canEditChartProfileData && (
                  <DropdownMenuItem
                    {...menuState}
                    onClick={addWithSelectedCategory}
                    key="add"
                  >
                    <Icon name="add" />
                    {`Add ${text.article} ${addText}`}
                  </DropdownMenuItem>
                )}
                <DropdownMenuItem
                  {...menuState}
                  onClick={hideCategory}
                  key="hide"
                >
                  <Icon name="visibility_off" />
                  {`Hide section`}
                </DropdownMenuItem>
              </DropdownMenu>
            </Portal>
          </>
        )}
      </PositionedButtonWrapper>
      {children}
    </PositionedSection>
  );
};
