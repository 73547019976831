import { transparentize } from "polished";
import { colors } from "../../constants/theme";
import { ChartCategory, GetChartDataQuery } from '../../data/graphql/generated/graphql';
import { ChartHeights } from "./Charts/ChartBubble";
import { ChartTypes } from "./Timeline/DisplayDataContext";

export type Color = {
  color: string,
  opacity: number,
}

export type ColorConfig = {
  fill: Color;
  stroke: Color;
};

export type ChartConfigType = {
  category: ChartCategory;
  chartType: ChartTypes;
  colors: ColorConfig;
  text: {
    singular: string;
    plural: string;
    article: string;
  };
  filter: {
    colors: Color;
  };
  shouldUseColors?: boolean;
  chartHeightType?: ChartHeights;
  canUseSpan?: boolean;
  canUseSubCategories?: boolean;
};

export const getTransparentColor = (color: Color) => transparentize(1- color.opacity, color.color);

export const getChartConfig = (chart: ChartCategory): ChartConfigType => {
  switch (chart) {
    case ChartCategory.Symptoms:
      return {
        category: ChartCategory.Symptoms,
        chartType: ChartTypes.chartBubble,
        colors: {
          fill: {
            color: colors.purple,
            opacity: 0.7,
          },
          stroke: {
            color: colors.purple,
            opacity: 0.5,
          },
        },
        filter: {
          colors: {
            color: colors.burgundy,
            opacity: 1,
          },
        },
        text: {
          plural: "Symptoms",
          singular: "Symptom",
          article: "a",
        },
        canUseSpan: true,
        canUseSubCategories: true,
      };
    case ChartCategory.LifeEvents:
      return {
        category: ChartCategory.LifeEvents,
        chartType: ChartTypes.chartBubble,
        colors: {
          fill: {
            color: colors.tangerine,
            opacity: 0.7,
          },
          stroke: {
            color: colors.tangerine,
            opacity: 0.5,
          },
        },
        filter: {
          colors: {
            color: "#796449",
            opacity: 1,
          },
        },
        text: {
          plural: "Life Events",
          singular: "Life event",
          article: "a",
        },
        canUseSpan: true,
      };
    case ChartCategory.InjuriesIllnesses:
      return {
        category: ChartCategory.InjuriesIllnesses,
        chartType: ChartTypes.chartBubble,
        colors: {
          fill: {
            color: colors.red,
            opacity: 0.55,
          },
          stroke: {
            color: colors.red,
            opacity: 0.3,
          },
        },
        filter: {
          colors: {
            color: "#A46462",
            opacity: 1,
          },
        },
        text: {
          plural: "Injuries & Illnesses",
          singular: "Injury or illness",
          article: "an",
        },
        canUseSpan: true,
      };
    case ChartCategory.Hospitalizations:
      return {
        category: ChartCategory.Hospitalizations,
        chartType: ChartTypes.chartBubble,
        colors: {
          fill: {
            color: colors.red,
            opacity: 0.55,
          },
          stroke: {
            color: colors.red,
            opacity: 0.3,
          },
        },
        filter: {
          colors: {
            color: "#A46462",
            opacity: 1,
          },
        },
        text: {
          plural: "Hospital & ER Visits",
          singular: "Hospital or ER visit",
          article: "a",
        },
        shouldUseColors: true,
        canUseSpan: true,
        chartHeightType: ChartHeights.CONDENSED,
      };
    case ChartCategory.Diagnoses:
      return {
        category: ChartCategory.Diagnoses,
        chartType: ChartTypes.chartBubble,
        colors: {
          fill: {
            color: colors.blue,
            opacity: 0.45,
          },
          stroke: {
            color: colors.blue,
            opacity: 0.3,
          },
        },
        filter: {
          colors: {
            color: "#357C9F",
            opacity: 1,
          },
        },
        text: {
          plural: "Diagnoses",
          singular: "Diagnosis",
          article: "a",
        },
        chartHeightType: ChartHeights.CONDENSED,
      };
    case ChartCategory.TestsImaging:
      return {
        category: ChartCategory.TestsImaging,
        chartType: ChartTypes.chartBubble,
        colors: {
          fill: {
            color: colors.blue,
            opacity: 0.3,
          },
          stroke: {
            color: colors.blue,
            opacity: 0.25,
          },
        },
        filter: {
          colors: {
            color: "#357C9F",
            opacity: 1,
          },
        },
        text: {
          plural: "Tests & Imaging",
          singular: "Test or imaging",
          article: "a",
        },
        chartHeightType: ChartHeights.CONDENSED,
      };
    case ChartCategory.SurgeriesProcedures:
      return {
        category: ChartCategory.SurgeriesProcedures,
        chartType: ChartTypes.chartBubble,
        colors: {
          fill: {
            color: colors.yellow,
            opacity: 0.8,
          },
          stroke: {
            color: colors.yellow,
            opacity: 0.9,
          },
        },
        filter: {
          colors: {
            color: '#796449',
            opacity: 1,
          },
        },
        text: {
          plural: "Surgeries & Procedures",
          singular: "Surgery or procedure",
          article: "a",
        },
        chartHeightType: ChartHeights.CONDENSED,
      };
    case ChartCategory.TreatmentsSelfCare:
      return {
        category: ChartCategory.TreatmentsSelfCare,
        chartType: ChartTypes.chartRangeBar,
        colors: {
          fill: {
            color: colors.green,
            opacity: 0.8,
          },
          stroke: {
            color: colors.green,
            opacity: 0.8,
          },
        },
        filter: {
          colors: {
            color: colors.forest,
            opacity: 1,
          },
        },
        text: {
          plural: "Medications & Treatments",
          singular: "Medication or treatment",
          article: "a",
        },
        canUseSpan: true,
      };
    case ChartCategory.SubstanceUse:
      return {
        category: ChartCategory.SubstanceUse,
        chartType: ChartTypes.chartRangeBar,
        colors: {
          fill: {
            color: colors.burgundy,
            opacity: 0.6,
          },
          stroke: {
            color: colors.burgundy,
            opacity: 0.7,
          },
        },
        filter: {
          colors: {
            color: colors.burgundy,
            opacity: 1,
          },
        },
        text: {
          plural: "Substance use",
          singular: "Substance use",
          article: "a",
        },
        shouldUseColors: true,
        canUseSpan: true,
      };
    default:
      throw new Error(`No color for chart type, ${chart}`);
  }
};

// TODO: why don't these get typed correctly from the backend?
export type ChartDataDisplay = Omit<
  GetChartDataQuery["getChartDataForChartProfile"][0],
  "timestampStart" | "timestampEnd"
> & {
  timestampStart: Date;
  timestampEnd?: Date;
};
export type ChartDataDisplayArray = ChartDataDisplay[];

export const replaceCategoryColors = ({
  color,
  categoryColors,
}: {
  color: string;
  categoryColors: ColorConfig;
}) => ({
  ...categoryColors,
  fill: {
    ...categoryColors.fill,
    color,
  },
  stroke: {
    ...categoryColors.stroke,
    color,
  },
});
