import React from "react";
import styled from "styled-components";

import { ReactComponent as Logo } from "../assets/images/logo.svg";
import { LogoIconLeft } from "./base/LogoIconLeft";
import ErrorImg from "../assets/images/error.png";
import { colors } from "../constants/theme";

const PageWrapper = styled.div({
  width: "calc(100vw - 40px)",
  height: "calc(100vh - 40px)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  textAlign: "center",
  padding: 20,
  color: colors.dark,
  lineHeight: 1.3,
  h1: {
    fontSize: 21,
  },
});

const StyledLogoIconLeft = styled(LogoIconLeft)({
  flexGrow: 0,
});

const Top = styled.div({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: 20,
});

export const StaticPageWrapper = ({ children }: { children: React.ReactNode }) => (
  <PageWrapper>
    <Top>
      <Logo />
      {children}
    </Top>
    <StyledLogoIconLeft />
  </PageWrapper>
);


export const ErrorFallback = () => (
  <StaticPageWrapper>
    <h1>Oops, something went wrong</h1>
    <p>Please try again later.</p>
    <img src={ErrorImg} alt="Pictal error" />
  </StaticPageWrapper>
);
