import { useEffect, useMemo } from "react";
import { Redirect, RouteComponentProps, Router } from "@reach/router";
import { ApolloProvider, useApolloClient } from "@apollo/client";
import createPersistedState from "use-persisted-state";

import { Summary } from "./components/Summary";
import { NavBar } from "./components/NavBar";
import { apolloClient } from "./data/store";
import { MyChartDataAccessContext } from "./context/MyChartDataAccessContext";
import { AuthProvider, getAccessToken, useAuth } from "./context/AuthContext";
import { DataModalContextProvider } from "./components/timeline/DataModalContext";
import { ChartDataAccessType, useMyChartDataAccessLazyQuery } from "./data/graphql/generated/graphql";
import { TimelinePage } from "./components/TimelinePage";
import { DownloadModeContextProvider } from "./context/DownloadModeContext";
import { Login } from "./components/Login";
import { ConfirmEmail } from "./components/ConfirmEmail";
import { Logout } from "./components/Logout";
import { SignUp } from "./components/Signup";
import { AuthLoading } from "./components/AuthLoading";
import { AuthWrapper, Welcome } from "./components/Welcome";
import { ResetPassword } from "./components/ResetPassword";
import { TodayProvider } from "./components/timeline/TodayContext";
import { DisplayDataProvider } from "./components/timeline/Timeline/DisplayDataContext";

export const routes = {
  login: "/login",
  logout: "/logout",
  signup: "/signup",
  confirmEmail: "/confirm-email",
  resetPassword: "/reset-password",
  timeline: "/timeline",
  summary: "/summary",
  root: "/",
};

const AuthedNoNavRoutes = () => (
  <Router>
    {/* Once users are confirmed they'll stay on that URL and end up here */}
    <Welcome path={routes.confirmEmail} />
    <AuthedApp default />
  </Router>
);

const AuthedRoutes = () => (
  <>
    <NavBar />
    <Router>
      <TimelinePage path={routes.timeline} />
      <Summary path={routes.summary} />
      <Redirect from={routes.root} to={routes.timeline} noThrow />
      <RedirectToTimeline default />
    </Router>
  </>
);

const RedirectToTimeline = (props: RouteComponentProps) => <Redirect to={routes.timeline} noThrow />;

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <AuthProvider>
        <Router>
          <Logout path={routes.logout} />
          <AppInner default />
        </Router>
      </AuthProvider>
    </ApolloProvider>
  );
};

const AppInner = (props: RouteComponentProps) => {
  const auth = useAuth();
  if (!auth || auth.userLoading) {
    return <AuthLoading />
  }
  const isLoggedIn = auth?.currentUser;

  return isLoggedIn ? <AuthedNoNavRoutes /> : <UnauthedApp />;
};

const useCurrentlyViewingChartProfileIdState = createPersistedState<string | null>(
  // A misnomer: this is actually the chartProfileId. Kept as-is for historical consistency.
  "currentlyViewingUserId"
);

function AuthedApp(props: RouteComponentProps) {
  const auth = useAuth();
  const client = useApolloClient();

  const [currentlyViewingChartProfileId, setCurrentlyViewingChartProfileId] =
    useCurrentlyViewingChartProfileIdState(null);

  const [myChartDataAccessLazyQuery, { data }] = useMyChartDataAccessLazyQuery({
    client,
  });

  const asSuperAdmin = !!auth?.asSuperAdmin;

  useEffect(() => {
    const accessToken = getAccessToken();

    if (accessToken) {
      myChartDataAccessLazyQuery({
        variables: {
          asSuperAdmin,
        },
      });
    }
  }, [myChartDataAccessLazyQuery, asSuperAdmin]);

  const currentlyViewingChartProfile = useMemo(() => {
    // Decide who to display
    const displayId =
      // Use whoever we've saved in storage
      currentlyViewingChartProfileId ||
      // Or the owner
      data?.myChartDataAccess.find(
        (access) => access.accessType === ChartDataAccessType.Owner
      )?.aboutChartProfile.id ||
      // Fall back to the first option
      (!!data?.myChartDataAccess.length
        ? data?.myChartDataAccess[0].aboutChartProfile.id
        : "");
    return data?.myChartDataAccess.find(
      (access) => access.aboutChartProfile.id === displayId
    )?.aboutChartProfile;
  }, [data?.myChartDataAccess, currentlyViewingChartProfileId]);

  const userContextValue = {
    myChartDataAccess: data?.myChartDataAccess || [],
    currentlyViewingChartProfile: currentlyViewingChartProfile || null,
    setCurrentlyViewingChartProfileId,
  };

  return (
    <TodayProvider>
      <MyChartDataAccessContext.Provider value={userContextValue}>
        <DisplayDataProvider>
          <DataModalContextProvider>
            <DownloadModeContextProvider>
              <AuthedRoutes />
            </DownloadModeContextProvider>
          </DataModalContextProvider>
        </DisplayDataProvider>
      </MyChartDataAccessContext.Provider>
    </TodayProvider>
  );
}

const UnauthedApp = () => (
  <AuthWrapper>
    <Router>
      <Login path={routes.login} />
      <SignUp path={routes.signup} />
      <ConfirmEmail path={routes.confirmEmail} />
      <ResetPassword path={routes.resetPassword} />
      <RedirectToLogin default />
    </Router>
  </AuthWrapper>
);

const RedirectToLogin = (props: RouteComponentProps) => (
  <Redirect to={routes.login} noThrow />
);

export default App;
