import React, { Dispatch, SetStateAction } from "react";
import { ChartDataAccessType, ChartProfile } from "../data/graphql/generated/graphql";
import { missingCaseError } from "../helpers/missingCaseError";

export const ownsChartProfile = (myChartDataAccess: MyChartDataAccessContextInterface) => {
    if (!myChartDataAccess.currentlyViewingChartProfile) {
      return false;
    }

    const currentlyViewingChartProfileAccessType =
      myChartDataAccess.myChartDataAccess.find(
        (mcda) =>
          mcda.aboutChartProfile.id ===
          myChartDataAccess.currentlyViewingChartProfile?.id
      );

    return currentlyViewingChartProfileAccessType?.accessType === ChartDataAccessType.Owner;
};

export const canEditChartProfile = (myChartDataAccess: MyChartDataAccessContextInterface) => {
  if (!myChartDataAccess.currentlyViewingChartProfile) {
    return false;
  }
  const currentlyViewingChartProfileAccessType =
    myChartDataAccess.myChartDataAccess.find(
      (mcda) =>
        mcda.aboutChartProfile.id ===
        myChartDataAccess.currentlyViewingChartProfile?.id
    );

  if (!currentlyViewingChartProfileAccessType) {
    return false;
  }
  switch (currentlyViewingChartProfileAccessType.accessType) {
    case ChartDataAccessType.SuperAdmin:
    case ChartDataAccessType.Owner:
    case ChartDataAccessType.Write:
      return true;
    case ChartDataAccessType.Read:
      return false;

    default:
      return missingCaseError(currentlyViewingChartProfileAccessType.accessType);
  }
};

export type MyChartDataAccessChartProfile = Pick<ChartProfile, "id" | "displayName" | "dateOfBirth">;
type MyChartDataAccess = {
  aboutChartProfile: MyChartDataAccessChartProfile;
  accessType: ChartDataAccessType;
};

interface MyChartDataAccessContextInterface {
  myChartDataAccess: MyChartDataAccess[];
  currentlyViewingChartProfile: MyChartDataAccessChartProfile | null;
  setCurrentlyViewingChartProfileId: Dispatch<
    SetStateAction<string | null>
  >;
}

export const MyChartDataAccessContext =
  React.createContext<MyChartDataAccessContextInterface>({
    myChartDataAccess: [],
    currentlyViewingChartProfile: null,
    setCurrentlyViewingChartProfileId: () => {},
  });

export const ChartDataAccessTypeDisplayText: {[key in ChartDataAccessType]: string } = {
  [ChartDataAccessType.SuperAdmin]: 'Super Admin',
  [ChartDataAccessType.Owner]: 'Owner',
  [ChartDataAccessType.Write]: 'Editor',
  [ChartDataAccessType.Read]: 'Viewer',
}
