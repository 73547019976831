import { StaticPageWrapper } from "./ErrorFallback";
import MaintenanceImg from "../assets/images/maintenance.png";

export const MaintenanceMode = () => (
  <StaticPageWrapper>
    <h1>Sorry, Pictal is down for maintenance.</h1>
    <p>
      Our team is making some updates,
      <br />
      please check back soon.
    </p>
    <img src={MaintenanceImg} alt="Pictal maintenance" />
  </StaticPageWrapper>
);
