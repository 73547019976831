import { createContext, useCallback, useContext, useEffect, useMemo } from "react";
import createPersistedState from "use-persisted-state";

import { ChartConfigType, ChartDataDisplay, ChartDataDisplayArray, getChartConfig } from "../config";
import {
  ChartCategory,
  GetSubCategoriesForChartProfileQuery,
  useGetChartDataLazyQuery,
  useGetSubCategoriesForChartProfileLazyQuery,
} from "../../../data/graphql/generated/graphql";
import { MyChartDataAccessContext } from "../../../context/MyChartDataAccessContext";
import { flattenDates } from "../helpers";
import { extent } from "d3-array";
import { useToday } from "../TodayContext";

export enum ChartTypes {
  chartArea,
  chartBubble,
  chartRangeBar,
}

export interface DisplayData {
  config: ChartConfigType;
  data: ChartDataDisplayArray;
}

export interface FilteredDisplayData extends DisplayData {
  data: FilteredChartData[];
  isVisible: boolean;
}

export interface FilteredChartData extends ChartDataDisplay {
  isVisible: boolean;
}

const displayedCategories: ChartCategory[] = [
  ChartCategory.Symptoms,
  ChartCategory.Diagnoses,
  ChartCategory.Hospitalizations,
  ChartCategory.InjuriesIllnesses,
  ChartCategory.LifeEvents,
  ChartCategory.TreatmentsSelfCare,
  ChartCategory.SubstanceUse,
  ChartCategory.SurgeriesProcedures,
  ChartCategory.TestsImaging,
];

type HiddenDataCategories = ChartCategory[];
type HiddenDataIds = string[];
type HiddenChartProfileData = {
  dataIds: HiddenDataIds;
  dataCategories: HiddenDataCategories;
};
type HiddenData = {
  [key: string]: HiddenChartProfileData;
};

// A misnomer: this is actually the hiddenChartProfileData. Kept as-is for historical consistency
const useHiddenData = createPersistedState<HiddenData>("hiddenUserData");

const emptyHiddenData = {
    dataIds: [],
    dataCategories: [],
};

// Store hidden IDs and categories for each possibly viewed chart profile
const useHiddenChartProfileData = (chartProfileId?: string): [HiddenChartProfileData, (newChartProfileData: HiddenChartProfileData) => void] => {
  const [hiddenData, setHiddenData] = useHiddenData({});
  const currentChartDataHiddenData: HiddenChartProfileData = useMemo(
    () =>
      chartProfileId && hiddenData[chartProfileId]
        ? hiddenData[chartProfileId]
        : { ...emptyHiddenData },
    [hiddenData, chartProfileId]
  );

  const setCurrentUserHiddenChartProfileData = (newChartProfileData: HiddenChartProfileData) => {
    if (chartProfileId) {
      setHiddenData({
        ...hiddenData,
        [chartProfileId]: newChartProfileData,
      });
    }
  };

  return [currentChartDataHiddenData, setCurrentUserHiddenChartProfileData];
};

const useProvideDisplayData = (): DisplayContextInterface => {
  const myChartDataAccessContext = useContext(MyChartDataAccessContext);
  const [getChartDataLazyQuery, { data: getChartData }] = useGetChartDataLazyQuery();
  const [getSubCategoriesForChartProfileLazyQuery, { data: getSubCategoriesData }] =
    useGetSubCategoriesForChartProfileLazyQuery();
  const today = useToday();
  const currentlyViewingChartProfileId = myChartDataAccessContext.currentlyViewingChartProfile?.id;
  const [currentChartDataHiddenData, setCurrentUserHiddenChartProfileData] =
    useHiddenChartProfileData(currentlyViewingChartProfileId);

  useEffect(() => {
    if (currentlyViewingChartProfileId) {
      const chartProfileIdInt = parseInt(currentlyViewingChartProfileId);
      getChartDataLazyQuery({
        variables: {
          chartProfileId: chartProfileIdInt,
        },
      });
      getSubCategoriesForChartProfileLazyQuery({
        variables: {
          chartProfileId: chartProfileIdInt,
        },
      });
    }
  }, [
    getChartDataLazyQuery,
    getSubCategoriesForChartProfileLazyQuery,
    currentlyViewingChartProfileId,
  ]);

  // Do this manually for now until we can figure out type
  const dataWithFixedDates = useMemo(
    () =>
      getChartData?.getChartDataForChartProfile
        .map((d) => ({
          ...d,
          timestampStart: new Date(d.timestampStart),
          timestampEnd: d.timestampEnd ? new Date(d.timestampEnd) : undefined,
        }))
        // Data needs to be sorted to display properly. This will probably need to:
        // 1. Happen on the backend
        // 2. Be smarter about how it's calculated when we supported fancier dates
        .sort((a, b) => {
          if (a.always) {
            return -1;
          }
          if (b.always) {
            return 1;
          }
          if (a.timestampStart && !b.timestampStart) {
            return -1;
          }
          if (!a.timestampStart && b.timestampStart) {
            return 1;
          }
          if (!a.timestampStart && !b.timestampStart) {
            return 0;
          }
          if (
            a.timestampStart &&
            b.timestampStart &&
            a.timestampStart < b.timestampStart
          ) {
            return -1;
          }
          if (
            a.timestampStart &&
            b.timestampStart &&
            a.timestampStart > b.timestampStart
          ) {
            return 1;
          }
          return 0;
        }) || [],
    [getChartData?.getChartDataForChartProfile]
  );

  const fullDisplayData: DisplayData[] = useMemo(
    () =>
      displayedCategories.map((displayedCategory) => ({
        config: getChartConfig(displayedCategory),
        data: dataWithFixedDates.filter(
          (d) => d.chartCategory === displayedCategory
        ),
      })),
    [dataWithFixedDates]
  );

  const filteredDisplayData: FilteredDisplayData[] = useMemo(
    () =>
      fullDisplayData.map((dd) => ({
        ...dd,
        data: dd.data.map((d) => ({
          ...d,
          isVisible: !currentChartDataHiddenData.dataIds.includes(d.id),
        })),
        isVisible: !currentChartDataHiddenData.dataCategories.includes(
          dd.config.category
        ),
      })),
    [fullDisplayData, currentChartDataHiddenData]
  );

  const { fdd: visibleDisplayData, count: visibleDataCount } = useMemo(
    () =>
      filteredDisplayData.reduce(
        (prev, dd) => {
          const data = dd.data.filter((d) => d.isVisible);
          return {
            fdd: [
              ...prev.fdd,
              ...(dd.isVisible
                ? [
                    {
                      ...dd,
                      data,
                    },
                  ]
                : []),
            ],
            count: prev.count + (dd.isVisible ? data.length : 0),
          };
        },
        {
          fdd: [] as FilteredDisplayData[],
          count: 0,
        }
      ),
    [filteredDisplayData]
  );

  const minMaxDates = useMemo(() => {
    const visibleFixedDates = dataWithFixedDates.filter(
      (d) =>
        !currentChartDataHiddenData.dataIds.includes(d.id) &&
        !currentChartDataHiddenData.dataCategories.includes(d.chartCategory)
    );
    const allDates = flattenDates(visibleFixedDates);
    if (!allDates.length) {
      return [today];
    }

    return extent([...allDates, today]) as Date[];
  }, [dataWithFixedDates, today, currentChartDataHiddenData]);

  const isSingleDataPoint = dataWithFixedDates.length === 1;

  const toggleCategory = useCallback(
    (category: ChartCategory) => {
      const existingHiddenCategories = currentChartDataHiddenData.dataCategories;
      const newHiddenCategories = existingHiddenCategories.includes(category)
        ? existingHiddenCategories.filter((hct) => hct !== category)
        : [...existingHiddenCategories, category];

      setCurrentUserHiddenChartProfileData({
        ...currentChartDataHiddenData,
        dataCategories: newHiddenCategories,
      });
    },
    [setCurrentUserHiddenChartProfileData, currentChartDataHiddenData]
  );

  const toggleData = useCallback(
    (id: string) => {
      const existingHiddenDataIds = currentChartDataHiddenData.dataIds;
      const newHiddenDataIds = existingHiddenDataIds.includes(id)
        ? existingHiddenDataIds.filter((hdi) => hdi !== id)
        : [...existingHiddenDataIds, id];

      setCurrentUserHiddenChartProfileData({
        ...currentChartDataHiddenData,
        dataIds: newHiddenDataIds,
      });
    },
    [setCurrentUserHiddenChartProfileData, currentChartDataHiddenData]
  );

  const subCategories = useMemo(() => getSubCategoriesData?.getSubCategoriesForChartProfile, [getSubCategoriesData]);

  const hiddenDataCount = dataWithFixedDates.length - visibleDataCount;

  return useMemo(
    () => ({
      filteredDisplayData,
      visibleDisplayData,
      visibleDataCount,
      hiddenDataCount,
      minMaxDates,
      isSingleDataPoint,
      toggleCategory,
      toggleData,
      subCategories,
    }),
    [
      filteredDisplayData,
      visibleDisplayData,
      visibleDataCount,
      hiddenDataCount,
      minMaxDates,
      isSingleDataPoint,
      toggleCategory,
      toggleData,
      subCategories,
    ]
  );
};

interface DisplayContextInterface {
  filteredDisplayData: FilteredDisplayData[];
  visibleDisplayData: FilteredDisplayData[];
  visibleDataCount: number;
  hiddenDataCount: number;
  minMaxDates: Date[];
  isSingleDataPoint: boolean;
  toggleCategory: (category: ChartCategory) => void;
  toggleData: (id: string) => void;
  subCategories?: GetSubCategoriesForChartProfileQuery['getSubCategoriesForChartProfile'];
}

export const DisplayDataContext = createContext<DisplayContextInterface>({
  filteredDisplayData: [],
  visibleDisplayData: [],
  visibleDataCount: 0,
  hiddenDataCount: 0,
  minMaxDates: [],
  isSingleDataPoint: false,
  toggleCategory: () => {},
  toggleData: () => {},
  subCategories: undefined,
});

export const DisplayDataProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const displayData = useProvideDisplayData();
  return (
    <DisplayDataContext.Provider value={displayData}>
      {children}
    </DisplayDataContext.Provider>
  );
};

export const useDisplayData = () => {
  return useContext(DisplayDataContext);
};
