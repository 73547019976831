import React, { useCallback, useState } from 'react';
import styled, { CSSObject } from "styled-components";
import { Disclosure, DisclosureContent, DisclosureStateReturn } from "reakit/Disclosure";
import { colors } from "../../../constants/theme";
import { ColorConfig, replaceCategoryColors, getTransparentColor } from "../config";
import { FilteredDisplayData, FilteredChartData, useDisplayData, ChartTypes } from "../Timeline/DisplayDataContext";
import { SubCategory } from "../../../data/graphql/generated/graphql";
import { useMemo } from "react";
import { useDataModal } from "../DataModalContext";
import { NeutralButton } from '../../base/NeutralButton';
import { getThreeButtonsColors } from '../DataModal/ThreeButtons';
import { analytics } from '../../../analytics';
import { EVENTS } from '../../../analytics/events';
import { MyChartDataAccessContext, canEditChartProfile } from '../../../context/MyChartDataAccessContext';
import { Icon } from '../../base/Icon';

export const FILTER_PANE_PADDING = 12;

const FilterSectionButton = styled(NeutralButton)(
  ({ $color }:
  {
    $color: string;
  }) => ({
    color: $color,
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 8,
    fontSize: 15,
    fontFamily: "Graphik Medium",
    height: 40,
    paddingTop: 0,
    paddingBottom: 0,
    // prevents weird hover wrapping when zoomed in
    whiteSpace: "nowrap" as const,
  })
);

const FilterSectionWrapper = styled("div")({
  marginBottom: FILTER_PANE_PADDING,
  backgroundColor: colors.white,
  borderColor: colors.light110,
  borderWidth: 1,
  borderStyle: "solid",
  borderRadius: 3,
});

const Items = styled('ul')({
  margin: 0,
  padding: 0,
  listStyle: 'none',
});

const Item = styled("li")({
  marginLeft: 30,
  paddingTop: 10,
  paddingBottom: 10,
  fontFamily: "Graphik Regular",
  fontSize: 15,
  color: colors.dark85,
  display: "flex",
  alignItems: "center",
  gap: 15,
  cursor: "pointer",
  borderColor: colors.light,
  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
  '&:last-child': {
    borderBottom: 'none',
  },
});

const circleSize = 8;

const Circle = styled('div')(({ $color }: { $color: string }) => ({
  width: circleSize,
  height: circleSize,
  borderRadius: '100%',
  backgroundColor: $color,
  pointerEvents: 'none',
  flexShrink: 0,
} as CSSObject));

const FilterItemVisibleButton = styled(NeutralButton)(({
  $isHidden,
}: {
  $isHidden: boolean;
}) => ({
  opacity: $isHidden ? 0 : 1,
  flexShrink: 0,
  flexGrow: 0,
}));

const FilterItemLabel = styled('span')({
  flexGrow: 1,
  lineHeight: '20px',
});

const FilterItem = ({
  data,
  color,
  toggleData,
}: {
  data: FilteredChartData;
  color: ColorConfig;
  toggleData: (id: string) => void;
}) => {
  const { showModal, setModalData } = useDataModal();
  const [isHovered, setIsHovered] = useState(false);

  const handleClick: React.MouseEventHandler<HTMLLIElement> = (e) => {
    e.preventDefault();

    setModalData(data);
    showModal();
    analytics.track({
      event: EVENTS.CLICK_FILTER_PANE_EVENT,
      properties: {
        chartCategory: data.chartCategory
      }
    });
  };

  const handleVisibleClick: React.MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.preventDefault();
    e.stopPropagation();
    analytics.track({
      event: data.isVisible ? EVENTS.CLICK_FILTER_PANE_HIDE_EVENT : EVENTS.CLICK_FILTER_PANE_SHOW_EVENT,
      properties: {
        chartCategory: data.chartCategory,
      },
    });
    toggleData(data.id);
  };

  const shouldShowVisibleIcon = isHovered || !data.isVisible;

  return (
    <Item
      onClick={handleClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Circle $color={getTransparentColor(color.fill)} />
      <FilterItemLabel>{data.label}</FilterItemLabel>
      <FilterItemVisibleButton
        color={colors.dark75}
        onClick={handleVisibleClick}
        $isHidden={!shouldShowVisibleIcon}
      >
        <Icon name={data.isVisible ? 'visibility' : 'visibility_off'} style={{ verticalAlign: "middle" }} />
      </FilterItemVisibleButton>
    </Item>
  );
};

const FilterTop = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const sortData = ({
  displayData,
  subCategories,
}: {
  displayData: FilteredDisplayData;
  subCategories?: SubCategory[];
}) => {
  if (!displayData.config.canUseSubCategories || !subCategories) {
    return displayData.data.sort(alphabeticalCompare);
  }

  const dataBySubCategory = displayData.data.reduce((accumulator, chartData) => {
    const subCategoryId = chartData.subCategory?.id;

    if (!subCategoryId) {
      return accumulator;
    }

    return {
      ...accumulator,
      [subCategoryId]:
        subCategoryId in accumulator
          ? [...accumulator[subCategoryId], chartData]
          : [chartData],
    };
  }, {} as { [key: SubCategory["id"]]: FilteredChartData[] });

  // Order in the same order subCategories appear elsewhere
  return subCategories.reduce((alphabetizedListSoFar, subCategory) => {
    const subCategoryData = dataBySubCategory[subCategory.id];

    if (!subCategoryData) {
      return alphabetizedListSoFar;
    }

    return [...alphabetizedListSoFar, ...subCategoryData.sort(alphabeticalCompare)]
  }, [] as FilteredChartData[]);
};

function alphabeticalCompare(a: FilteredChartData, b: FilteredChartData) {
  if (a.label && !b.label) {
    return -1
  }
  if (!a.label && b.label) {
    return 1;
  }
  if (!a.label || !b.label) {
    return 0;
  }
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
}

const AddData = ({ displayData }: { displayData: FilteredDisplayData }) => {
    const { setModalData, showModal } = useDataModal();
    const handleClick = () => {
      setModalData({
        chartCategory: displayData.config.category,
      });
      showModal();
      analytics.track({
        event: EVENTS.CLICK_ADD_TO_TIMELINE,
        properties: {
          clickLocation: 'filterPane',
          chartCategory: displayData.config.category,
        },
      });
    };

  return (
    <>
      <Item onClick={handleClick}>
        <Icon name="add" /> Add{" "}
        {displayData.config.text.article}{" "}
        {displayData.config.text.singular.toLocaleLowerCase()}
      </Item>
    </>
  );
};

export const FilterSection = ({
  displayData,
  disclosure,
}: {
  displayData: FilteredDisplayData;
  disclosure: DisclosureStateReturn;
}) => {
  const { toggleCategory, toggleData, subCategories } = useDisplayData();
  const sortedData = useMemo(
    () => sortData({ displayData, subCategories }),
    [displayData, subCategories]
  );
  const [isHovered, setIsHovered] = useState(false);
    const myChartDataAccessContext = React.useContext(MyChartDataAccessContext);
  const canEditChartProfileData = canEditChartProfile(myChartDataAccessContext);

  const handleVisibleClick = () => {
    if (displayData.isVisible) {
      disclosure.hide();
      analytics.track({
        event: EVENTS.CLICK_FILTER_PANE_HIDE_CATEGORY,
        properties: {
          chartCategory: displayData.config.category,
        },
      });
    } else {
      analytics.track({
        event: EVENTS.CLICK_FILTER_PANE_SHOW_CATEGORY,
        properties: {
          chartCategory: displayData.config.category,
        },
      });
    }
    toggleCategory(displayData.config.category);
  };

  const visibleIcon = (
    <NeutralButton color={colors.dark75} onClick={handleVisibleClick}>
      {displayData.isVisible ? (
        <Icon name="visibility" style={{ verticalAlign: "middle" }} />
      ) : (
        <Icon name="visibility_off" style={{ verticalAlign: "middle" }} />
      )}
    </NeutralButton>
  );

  const shouldShowVisibleIcon = isHovered || !displayData.isVisible;

  const getFilterItemColor = useCallback(
    ({ data }: { data: FilteredChartData }) => {
      if (data.subCategory) {
        return replaceCategoryColors({
          categoryColors: displayData.config.colors,
          color: data.subCategory.color,
        });
      }
      if (
        displayData.config.chartType === ChartTypes.chartRangeBar &&
        data.helped
      ) {
        return replaceCategoryColors({
          categoryColors: displayData.config.colors,
          color: getThreeButtonsColors(data.helped),
        });
      }
      return displayData.config.colors;
    },
    [displayData.config.colors, displayData.config.chartType]
  );

  // If there's no data and they can't add more, don't show the section at all
  if (!sortedData.length && !canEditChartProfileData) {
    return null;
  }

  return (
    <FilterSectionWrapper
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <FilterTop>
        <Disclosure
          {...disclosure}
          as={FilterSectionButton}
          $color={displayData.config.filter.colors.color}
        >
          <Icon name={disclosure.visible ? 'keyboard_arrow_down' : 'keyboard_arrow_right'} />
          {displayData.config.text.plural}
        </Disclosure>
        {shouldShowVisibleIcon && visibleIcon}
      </FilterTop>
      <DisclosureContent {...disclosure}>
        <Items>
          {sortedData.map((d) => (
            <FilterItem
              data={d}
              key={d.id}
              color={getFilterItemColor({ data: d })}
              toggleData={toggleData}
            />
          ))}
          {canEditChartProfileData && (
            <AddData key="add-btn" displayData={displayData} />
          )}
        </Items>
      </DisclosureContent>
    </FilterSectionWrapper>
  );
};
